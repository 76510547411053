import { CommunicationProps } from "../../types/communication";
import api from "../api";

interface IProps {
  token: string;
  id?: number;
}

export async function deleteCommunicationRequest({
  token,
  id,
}: IProps): Promise<CommunicationProps> {
  try {
    const response = await api.delete(`/communication/${id}`, {
      params: { token },
      headers: {
        jwt: token,
      },
    });

    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }

    throw new Error();
  }
}
