import { createContext, useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { auth } from "../endpoints/auth";
import { LanguageModel } from "../models/LanguageModel";
import { PeopleModel } from "../models/PeopleModel";
import { UserGdy } from "../models/UserGdy";
import { URLS } from "../types/env";
import { format } from "../utils/format";
import api from '../services/api';
import cookies from "../utils/cookies";

interface AuthContextData {
  setLanguage: React.Dispatch<any>;
  language: any;
  setLanguageSelected: React.Dispatch<
    React.SetStateAction<LanguageModel | undefined>
  >;
  languageSelected?: LanguageModel | any;
  user?: UserGdy;
  dataPeoples: any;
  loading: boolean;
  setLinksDinamic: React.Dispatch<any>;
  linksDinamic: any;
  setLanguageLinks: React.Dispatch<any>;
  languageLinks: any;
  setTranslations: React.Dispatch<any>;
  translations: any;
  token: any;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export function Auth({ children }: { children: React.ReactNode }) {
  const [language, setLanguage] = useState<any>();
  const [languageSelected, setLanguageSelected] = useState<LanguageModel>();
  const [user, setUser] = useState<UserGdy>();
  const [dataPeoples, setDataPeoples] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [linksDinamic, setLinksDinamic] = useState<any>();
  const [languageLinks, setLanguageLinks] = useState<any>();
  const [translations, setTranslations] = useState<any>();
  const [token, setToken] = useCookies(["JWT"]);

  const changeLanguage = useCallback((
    lng: any,
    dataLinks?: any,
    dataTranslations?: any
  ) => {
    setLanguage(lng);
    !linksDinamic && dataLinks
      ? setLanguageLinks(dataLinks["links"][lng])
      : setLanguageLinks(linksDinamic["links"][lng]);
    if (!translations && dataTranslations) {
      document.title = dataTranslations["translations"][lng].pageTitle;
      setLanguageSelected(dataTranslations["translations"][lng]);
    } else {
      document.title = translations["translations"][lng].pageTitle;
      setLanguageSelected(translations["translations"][lng]);
    }
  }, [linksDinamic, translations]);

  const isAuth = useCallback(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userToken = urlParams.get("jwt");

    if (!userToken) return null;

    api.defaults.headers.common.jwt = userToken;
    api.defaults.params = { token: userToken };

		cookies.setCookie('@TOKEN', userToken, 10);
    setToken("JWT", userToken);

    window.location.href = URLS.BASE_URL;
  }, [setToken]);

  const getUser = useCallback(async () => {
    let languageUser = "en";
    try {
      const dataResult: UserGdy = await auth.getUserProfile(token?.JWT);
      dataResult.name = format.formatName(dataResult.name);
      dataResult.lastName = format.formatName(dataResult.lastName);
      setUser(dataResult);
      setLanguage(dataResult.language);
      languageUser = dataResult.language;
    } catch {
      setToken("JWT", "");
      window.location.href = URLS.URL_LOGIN;
    } finally {
      const promises = [
        auth.getLinksDinamic(token?.JWT),
        auth.getTranslation(token?.JWT)
      ]

      const promisesData = await Promise.all(promises);

      const dataLinks = promisesData[0];
      const dataTranslations = promisesData[1];

      setTranslations(dataTranslations);
      setLinksDinamic(dataLinks);
      changeLanguage(languageUser, dataLinks, dataTranslations);
    }
  }, [changeLanguage, setToken, token?.JWT]);

  const getPeoplesRh = useCallback(async () => {
    try {
      const dataResult: PeopleModel = await auth.getPeoples(token?.JWT);
      const filter = dataResult.peoples.map((element, index) => {
        return {
          key: index,
          text: (
            <div style={{ textTransform: "capitalize" }}>
              <a
                style={{ textDecoration: "none", color: "#000" }}
                href={`mailto:${element.email}`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="peopleName">
                  {element.firstName.toLocaleLowerCase()}{" "}
                  {element.lastName.toLocaleLowerCase()}
                </div>
                <div style={{ marginTop: "2%" }}>
                  {element.title.toLocaleLowerCase()}{" "}
                </div>
                <div style={{ marginTop: "2%" }}>{element.country}</div>
              </a>
            </div>
          ),
          value: element.email,
          image: {
            avatar: true,
            src: `data:image/jpeg;base64,${element.photoBase64}`,
          },
        };
      });
      setDataPeoples(filter);
    } catch {
      console.warn();
    }
  }, [token?.JWT]);

  const loadData = useCallback(async () => {
    isAuth();

    if (token.JWT) {
      const promisses = [
        getPeoplesRh(),
        getUser(),
      ]
      await Promise.all(promisses);

      setLoading(false);
      api.defaults.headers.common.jwt = token.JWT;
      api.defaults.params = { token: token.JWT };

      return undefined;
    }

    window.location.href = URLS.URL_LOGIN;

    return undefined;
}, [getPeoplesRh, getUser, isAuth, token?.JWT])

  useEffect(() => {
    loadData();
  }, [])

  return (
    <AuthContext.Provider
      value={{
        language,
        setLanguage,
        languageSelected,
        setLanguageSelected,
        user,
        dataPeoples,
        loading,
        linksDinamic,
        setLinksDinamic,
        languageLinks,
        setLanguageLinks,
        translations,
        setTranslations,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
