import { useMutation } from '@tanstack/react-query';
import { showSwal, swallDelete } from '../../../utils/swal';
import { queryClient } from '../../../services/queryClient';
import { useContext } from 'react';
import MyContext from "../../../contexts/auth";
import { deleteCommunicationRequest } from '../../../services/communication/deleteCommunicationRequest';
import { deleteFileService } from '../../../services/fileService';

type DeleteRequestParamsProps = {
  id: number;
  fileName: string;
  index: number;
}

type useDeleteCommunicationProps = {
  setDeleteLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIndexLoading: React.Dispatch<React.SetStateAction<number>>;
}

export const useDeleteCommunication = ({ setDeleteLoading, setIndexLoading }: useDeleteCommunicationProps) => {
  const { languageSelected, token } = useContext(MyContext)

  const deleteCommunication = useMutation(
    async ({ id, fileName, index }: DeleteRequestParamsProps) => {
      setDeleteLoading(true);
      setIndexLoading(index);

      swallDelete(languageSelected)
        .then(async (result) => {
          if (result.isConfirmed) {
            await deleteCommunicationRequest({ token: token.JWT, id })
              .then(() => {
                showSwal(languageSelected.modal.swall.delete, "success", "green");
                queryClient.invalidateQueries(["COMMUNICATION_LIST"]);

                return deleteFileService(token.JWT, "COMMUNICATION", fileName);
              })
              .catch(() => {
                showSwal(languageSelected.modal.swall.nok, "error", "red");
                queryClient.invalidateQueries(["COMMUNICATION_LIST"]);
              })
              .finally(() => {
                setDeleteLoading(false);
                setIndexLoading(0);
              });
          }
        })
        .finally(() => {
          setDeleteLoading(false);
          setIndexLoading(0);
        })
    }
  );

  return {
    deleteCommunication,
  }
};
