const formatName = (name: string): string => {
  const lower = name.toLowerCase();
  const upperOneLetter = lower[0].toUpperCase();
  return (upperOneLetter + lower.substring(1, lower.length));
}

const formatFileName = (name: string): string => {
  return name.split("/")[1].replaceAll(" ", "");
}

const formatBlobSize = (bytes: number, decimals?: number, only?: string): string => {
  const K_UNIT = 1024;
  const SIZES = ["Bytes", "KB", "MB", "GB", "TB", "PB"];

  if(bytes === 0) {
      return "0 Byte";
  }

  if(only==="MB") {
      return (bytes / (K_UNIT*K_UNIT)).toFixed(decimals) + " MB";
  }

  const i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
  return `${parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals))} ${SIZES[i]}`;
}

const formatDateToMonthDayYear = (date: string, utc?:string) : string => {
  return new Date(date).toLocaleDateString(utc, { timeZone: 'UTC' });
}

const formatDate = (date: string) => {
  const dateFormat = new Date(date);
  return `${dateFormat.toLocaleDateString()}  ${dateFormat.toLocaleTimeString()}`;
}

const reverseString = (str: string): string => {
  let newString = "";
  for (let i = str.length - 1; i >= 0; i--) {
      newString += str[i];
  }
  return newString;
}

const normalize = (string: string): string => {
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export const format = {
  formatName,
  formatFileName,
  formatBlobSize,
  formatDate,
  reverseString,
  normalize,
  formatDateToMonthDayYear
}
