import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container as ContainerSUI,
  Dropdown,
  Icon,
  Image,
  Menu,
  Popup,
  SemanticICONS,
} from "semantic-ui-react";
import AuthContext from "../../../contexts/auth";
import { checkCondition } from "../../../utils/checkCondition";
import { Container } from "./styles";

export const HeaderGoodyear = () => {
  const navigate = useNavigate();
  const {
    language,
    setLanguage,
    languageSelected,
    setLanguageSelected,
    translations,
    setLanguageLinks,
    user,
    dataPeoples,
    linksDinamic,
    languageLinks,
  } = useContext(AuthContext);

  const changeeLanguage = (
    lng: any,
    dataLinkss?: any,
    dataTranslationss?: any
  ) => {
    setLanguage(lng);
    !linksDinamic && dataLinkss
      ? setLanguageLinks(dataLinkss["links"][lng])
      : setLanguageLinks(linksDinamic["links"][lng]);
    if (!translations && dataTranslationss) {
      document.title = dataTranslationss["translations"][lng].pageTitle;
      setLanguageSelected(dataTranslationss["translations"][lng]);
    } else {
      document.title = translations["translations"][lng].pageTitle;
      setLanguageSelected(translations["translations"][lng]);
    }
  };

  const trigger = (nameIcon: SemanticICONS, tooltipContent?: string) => {
    return (
      <span>
        <Popup
          disabled={!tooltipContent}
          inverted
          content={tooltipContent}
          trigger={
            <Icon
              name={nameIcon}
              link
              size="big"
              style={{ color: "#ffffff" }}
            />
          }
        />
      </span>
    );
  };

  return (
    <Container>
      <Menu inverted>
        <ContainerSUI className="header-menu-container">
          <div className="header-menu--left">
            <Link to="/web">
              <Image src="/logo.png" />
            </Link>

            <span className="titleHeader">TR Rewards Portal</span>

            <div className='header-menu-change-language'>
              <span
                className={`language-item ${checkCondition.checkConditions(
                  language,
                  "en",
                  "active",
                  ""
                )}`}
                onClick={() => changeeLanguage("en")}
              >
                EN
              </span>
              <span
                className={`language-item ${checkCondition.checkConditions(
                  language,
                  "es",
                  "active",
                  ""
                )}`}
                onClick={() => changeeLanguage("es")}
              >
                ES
              </span>
              <span
                className={`language-item ${checkCondition.checkConditions(
                  language,
                  "pt-BR",
                  "active",
                  ""
                )}`}
                onClick={() => changeeLanguage("pt-BR")}
              >
                PT
              </span>
            </div>
          </div>

          <div className='header-menu--right'>
            <Image src="/header-menu.png" />

            <div className="header-icons">
              <Icon
                name="home"
                link
                size="big"
                style={{ color: "#ffffff" }}
                onClick={() => navigate("/web")}
              />
              <Popup
                inverted
                content={languageSelected?.page?.communication}
                position="top left"
                trigger={
                  <Icon
                    name="mail"
                    link
                    size="big"
                    style={{ color: "#ffffff" }}
                    onClick={() => navigate("/communication")}
                  />
                }
              />

              <Dropdown
                pointing="top left"
                inline
                trigger={trigger(
                  "world",
                  languageSelected?.tooltip?.usefulLinks
                )}
              >
                <Dropdown.Menu>
                  {languageLinks &&
                    linksDinamic &&
                    languageLinks.map((e: any) => {
                      return (
                        <Dropdown.Item key={e.text}>
                          <a href={e.valHref} rel="noreferrer" target="_blank">
                            {e.text}
                          </a>
                        </Dropdown.Item>
                      );
                    })}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown
                inline
                pointing="top left"
                trigger={trigger(
                  "users",
                  languageSelected?.tooltip?.trContacts
                )}
                options={dataPeoples}
              />

              {user?.manager && (
                <>
                  <Popup
                    inverted
                    content={languageSelected?.page?.benefits}
                    position="top left"
                    trigger={
                      <Icon
                        name="heartbeat"
                        link
                        size="big"
                        style={{ color: "#ffffff" }}
                        onClick={() => navigate("/adm")}
                      />
                    }
                  />

                  <Popup
                    inverted
                    content={languageSelected?.page?.booklets}
                    position="top left"
                    trigger={
                      <Icon
                        name="book"
                        link
                        size="big"
                        style={{ color: "#ffffff" }}
                        onClick={() => navigate("/booklets")}
                      />
                    }
                  />
                </>
              )}
            </div>
          </div>
        </ContainerSUI>
      </Menu>
    </Container>
  );
};
