import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;

  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  flex: 1;
`;

export const Footer = styled.footer`
  width: 100%;
  margin-top: 20px;

  #footer {
    height: 56px;
    margin-top: 16px;
    padding: 0;

    clear: both;

    display: flex;
  }

  .info__version-and-year {
    display: flex;
    gap: 5px
  }
`;
