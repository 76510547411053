import { useContext, useState } from 'react'
import { CommunicationProps, CommunicationResponseDataProps } from '../../../../types/communication';
import { Button, Loader, Pagination, PaginationProps, Popup, Table as TableSUI } from 'semantic-ui-react';
import MyContext from "../../../../contexts/auth";
import { useDeleteCommunication } from '../../hooks/deleteCommunication';
import { onDownloadFile } from '../../utils/onDownloadFile';
import DataContext from '../../../../contexts/data';
import { format } from '../../../../utils/format';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { FooterWrapper, LinkUnderline } from './styles';

type TableProps = {
  communicationList?: CommunicationResponseDataProps;
  page?: number;
  handlePageChange(_: React.MouseEvent<HTMLAnchorElement>, { activePage }: PaginationProps): void;
  handleEdit(id: number): void;
  handleFormModalOpen(): void;
}

export function Table({ communicationList, page, handlePageChange, handleEdit, handleFormModalOpen }: TableProps) {
  const { token, user, languageSelected } = useContext(MyContext)
  const { resourcesCommunication } = useContext(DataContext);
  const [isDownloadLoading, setDownloadLoading] = useState(false);
  const [indexLoading, setIndexLoading] = useState(0);
  const [isDeleteLoading, setDeleteLoading] = useState(false);

  const handleDownloadFile = async (
    file?: CommunicationProps,
    index?: number
  ) => {
    try {
      setIndexLoading(index as number);
      setDownloadLoading(true);

      await onDownloadFile({
      token: token.JWT,
      file
      })
    } catch (error) {
      console.log(error);
    } finally {
      setIndexLoading(0);
      setDownloadLoading(false);
    }
  }

  const { deleteCommunication } = useDeleteCommunication({
    setDeleteLoading,
    setIndexLoading,
  });

  return (
    <TableSUI striped stackable>
      <TableSUI.Header>
        <TableSUI.Row textAlign="center">
          {languageSelected?.modal?.semantic?.table?.header?.communication?.map(
          (element: string, index: number) => {
          if (index === 0) {
            return (
              <TableSUI.HeaderCell textAlign="left">
                {element}
              </TableSUI.HeaderCell>
            );
          }

          if (index === 1) {
            const country = element.split("/")[0];
            const location = element.split("/")[1];

            return (
            <>
              <TableSUI.HeaderCell>{country}</TableSUI.HeaderCell>
              <TableSUI.HeaderCell>{location}</TableSUI.HeaderCell>
            </>
            );
          }

          if (index === 5) {
              const split = element.split("-");
              return (
              <TableSUI.HeaderCell>
                {split[0]} <br /> {split[1]}
              </TableSUI.HeaderCell>
              );
          }
            return <TableSUI.HeaderCell>{element}</TableSUI.HeaderCell>;
          }
          )}
        </TableSUI.Row>
    </TableSUI.Header>

    <TableSUI.Body key={resourcesCommunication}>
      {communicationList &&
      communicationList?.content.map(
        (element: CommunicationProps, index: number) => {
        const country = element?.facilities.facilityDescription.split("-")[0];
        const location = element?.facilities.facilityDescription.split("-")[1];
        return (
          <TableSUI.Row textAlign="center">
            <TableSUI.Cell textAlign="left">
              <LinkUnderline
                onClick={() => handleDownloadFile(element, index)}
              >
                {element?.subject}

                <span style={{ marginLeft: "1rem" }}>
                <Loader
                  active={
                  isDownloadLoading && indexLoading === index
                  }
                  inline
                  size="tiny"
                />
                </span>
              </LinkUnderline>
            </TableSUI.Cell>
            <TableSUI.Cell>{country}</TableSUI.Cell>
            <TableSUI.Cell>{location}</TableSUI.Cell>
            <TableSUI.Cell>{element?.email}</TableSUI.Cell>
            <TableSUI.Cell>
            {element?.employeeType.employeeTypeDescription}
            </TableSUI.Cell>
            <TableSUI.Cell>{element?.area}</TableSUI.Cell>
            <TableSUI.Cell>
            {format.formatDateToMonthDayYear(
              element?.date as string,
              languageSelected?.locales
            )}
            </TableSUI.Cell>
            <TableSUI.Cell>
            {
              <a
              style={{
                textDecoration: "none",
                color: "black",
              }}
              href={`mailto:${element?.emailUser}`}
              >
              {element?.user}
              </a>
            }
            </TableSUI.Cell>

            <TableSUI.Cell>{element.id}</TableSUI.Cell>

            <TableSUI.Cell>
            {user?.manager ? (
              <Button.Group basic size="small">
                <Popup
                  inverted
                  position="top left"
                  content={
                    languageSelected?.modal?.semantic?.tooltip
                    ?.edit
                  }
                  trigger={
                    <Button
                    basic
                    icon="edit"
                    onClick={() => handleEdit(element?.id)}
                    />
                  }
                />
                <Popup
                inverted
                position="top left"
                content={
                  languageSelected?.modal?.semantic?.tooltip
                  ?.open
                }
                trigger={
                  <Button
                  style={{ padding: "0" }}
                  onClick={() =>
                    handleDownloadFile(element, index)
                  }
                  loading={
                    isDownloadLoading &&
                    indexLoading === index
                  }
                  >
                  <AiOutlineFileSearch
                    size="18"
                    style={{ margin: "7px" }}
                  />
                  </Button>
                }
                />
                <Popup
                inverted
                position="top left"
                content={
                  languageSelected?.modal?.semantic?.tooltip
                  ?.delete
                }
                trigger={
                  <Button
                    icon="trash"
                    onClick={() =>
                      deleteCommunication.mutateAsync({
                      id: element.id,
                      fileName:
                        element.fileName as string,
                        index,
                      })
                    }
                    loading={
                      isDeleteLoading &&
                      indexLoading === index
                    }
                  />
                }
                />
              </Button.Group>
            ) : (
              <Button.Group basic size="medium">
                <Popup
                  basic
                  position="right center"
                  content={
                    languageSelected?.modal?.semantic?.tooltip
                    ?.open
                  }
                  trigger={
                    <Button
                    style={{ padding: "0" }}
                    onClick={() =>
                      handleDownloadFile(element, index)
                    }
                    loading={
                      isDownloadLoading &&
                      indexLoading === index
                    }>
                    <AiOutlineFileSearch
                      size="18"
                      style={{ margin: "7px" }}
                    />
                    </Button>
                  }
                />
              </Button.Group>
            )}
            </TableSUI.Cell>
          </TableSUI.Row>
        );
        }
      )}
    </TableSUI.Body>

    <TableSUI.Footer
      style={{
      backgroundColor: "#ffffff",
      position: "sticky",
      bottom: 0,
      }}
    >
      <TableSUI.Row>
      <TableSUI.Cell colSpan="20">
        <FooterWrapper>
        <Pagination
          activePage={page}
          totalPages={communicationList?.totalPages ?? 0}
          onPageChange={(e, data) => handlePageChange(e, data)}
          style={{
          textAlign: 'center',
          }}
        />
        {user?.manager && (
          <Button onClick={() => handleFormModalOpen()}>
          {languageSelected?.modal?.semantic?.tooltip?.register}
          </Button>
        )}
        </FooterWrapper>
      </TableSUI.Cell>
      </TableSUI.Row>
      </TableSUI.Footer>
    </TableSUI>
  )
}
