import axios from 'axios';
import { LinkModel } from '../models/LinkModel';
import { PeopleModel } from '../models/PeopleModel';
import { UserGdy } from '../models/UserGdy';
import { URLS } from "../types/env";
import { Booklet, BookletRequestProps } from '../models/BookletModel';
import api from '../services/api';

const urlEndpoint = URLS.URL_ENDPOINT;

const getRequestOptions = (tokenRequest: string) => {
    return {
        "jwt": tokenRequest
    };
}

const getUserProfile = async (tokenRequest: string): Promise<UserGdy> => {
    const user = await axios.get(`${urlEndpoint}/authdata`, {
        params: {
            token: tokenRequest
        },
        headers: getRequestOptions(tokenRequest)
    });
    return user.data;
}

const getPeoples = async (tokenRequest: string): Promise<PeopleModel> => {
    const peoples = await axios.get(`${urlEndpoint}/people`,
        {
            params: {
                token: tokenRequest,
                folderName: "JSON"
            },
            headers: getRequestOptions(tokenRequest)
        });
    return peoples.data;
}

const getPdfUser = async (tokenRequest: string): Promise<string> => {
    const pdf = await axios.get(`${urlEndpoint}/resources/pdf`, {
        params: {
            token: tokenRequest,
            folderName: "PDF"
        },
        headers: getRequestOptions(tokenRequest)
    });
    return pdf.data;
}

const getLinksDinamic = async (tokenRequest: string): Promise<LinkModel> => {
    const links = await axios.get(`${urlEndpoint}/resources/links`, {
        params: {
            token: tokenRequest,
            folderName: "JSON"
        },
        headers: getRequestOptions(tokenRequest)
    });
    return links.data;
}

const getTranslation = async (tokenRequest: string): Promise<any> => {
    const translations = await axios.get(`${urlEndpoint}/resources/translation`, {
        params: {
            token: tokenRequest,
            folderName: "JSON"
        },
        headers: getRequestOptions(tokenRequest)
    });
    return translations.data;
}

const getBookletRequest = async ({ token, language }: BookletRequestProps): Promise<Booklet> => {
    try {
        const response = await api.get('/resources/booklet', {
            params: {
              token,
              language: language.toUpperCase()
           },
            headers: {
                jwt: token,
            },
        });

        return response.data;
    } catch (error) {
          if (error instanceof Error) {
              throw new Error(error.message);
            }

          throw new Error();
      }
  };
export const auth = {
    getUserProfile,
    getPeoples,
    getPdfUser,
    getLinksDinamic,
    getTranslation,
    getBookletRequest
};
