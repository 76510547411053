import { useMutation } from '@tanstack/react-query';
import { CommunicationFormDataProps } from '../../../types/communication';
import { format } from '../../../utils/format';
import { showSwal } from '../../../utils/swal';
import { queryClient } from '../../../services/queryClient';
import { useContext } from 'react';
import AuthContext from '../../../contexts/auth';
import { useFormContext } from 'react-hook-form';
import { INITIAL_VALUES } from '../constants';
import { saveCommunicationRequest } from '../../../services/communication/saveCommunicationRequest';

type Props = {
  handleFormModalClose(): void;
  fileType: string;
  currentFile?: File;
}

export const useCreateCommunication = ({ handleFormModalClose, currentFile, fileType }: Props) => {
  const { languageSelected, user, token } = useContext(AuthContext);
  const { reset } = useFormContext();

  const createCommunication = useMutation({
    mutationFn: async (data: CommunicationFormDataProps) => {
      if (user) {
        return saveCommunicationRequest(token.JWT, {
          ...data,
          fileName: currentFile?.name,
          area: format.normalize(data?.area ?? ""),
          user: `${user.name} ${user.lastName}`,
          typeArquive: fileType,
        })
      } else {
        showSwal(languageSelected?.modal?.swall?.username, "error", "red");
      }

      return undefined
    },
    onSuccess: () => {
      showSwal(languageSelected?.modal?.swall?.created, "success", "green");
      handleFormModalClose();
    },
    onError: () => {
      showSwal(languageSelected?.modal?.swall?.conflict, "error", "red");
    },
    onSettled: (_res, _err, data) => {
      queryClient.invalidateQueries(["COMMUNICATION_LIST"]);

      reset({
        ...INITIAL_VALUES,
        emailUser: data?.emailUser,
        user: data?.user,
      });
    }
  });

  return {
    createCommunication
  }
};
