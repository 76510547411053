import { CommunicationProps } from '../../../types/communication';
import { downloadFileService } from '../../../services/fileService';
import { URLS } from '../../../types/env';
import { ResourceModel } from '../../../models/ResourceModel';
import dataProcessing from '../../../utils/dataProcessing';

type Props = {
    file?: CommunicationProps;
    token: string;
}

export const onDownloadFile = async ({ file, token }: Props) => {
    try {
        if (file?.fileName) {
          const downloadFileData: any = await downloadFileService(
            token,
            file.fileName,
            "COMMUNICATION"
          );

          if (downloadFileData === "105" || downloadFileData === "102") {
            window.location.href = URLS.BASE_URL;
            return;
          }

          const response: ResourceModel = downloadFileData;
          if (file.typeArquive) {
            dataProcessing.toBinary(
              response.byteArray,
              false,
              file.typeArquive,
              `${file.subject}-${response.name}`
            );
          }

        }
    } catch (error) {
        console.log(error);
    }
}
