import { createContext, useContext, useEffect, useState } from 'react';
import { resourceApi } from '../endpoints/resourceService';
import { CompaniesModel } from '../models/Companies';
import { ResourceModel } from '../models/ResourceModel';
import MyContext from './auth';

const DataContext = createContext<any>(null);

export function Data({ children }: { children: React.ReactNode }) {
    const {
        token,
    } = useContext(MyContext);
    const [resources, setResources] = useState<Array<ResourceModel>>([]);
    const [companies, setCompanies] = useState<CompaniesModel>();
    const [facilities, setFacilities] = useState<any>([]);
    const [resourcesCommunication, setResourcesCommunication] = useState<Array<ResourceModel>>([]);
    const [att, setAtt] = useState<number>(0);

    const getPdfs = async () => {
        const result: any = await resourceApi.dataPdf(token.JWT, false);
        setResources(result);
    }

    const getCompanies = async () => {
        const result: CompaniesModel = await resourceApi.getCompanies(token.JWT);
        setCompanies(result);
    }

    const getCommunications = async () => {
        const result:Array<ResourceModel> = await resourceApi.getAllFilesCommunication(token.JWT, false);
        setResourcesCommunication(result);
    }

    const getFacilities = async () => {
        const result:Array<any> = await resourceApi.getFacilities(token.JWT);
        setFacilities(result);
    }
    useEffect(() => {
        getPdfs();
        getCompanies();
        getCommunications();
        getFacilities();
    }, [att]);

    return (
        <DataContext.Provider value={{
            resources,
            setResources,
            resourcesCommunication,
            setResourcesCommunication,
            setAtt,
            companies,
            setCompanies,
            facilities
        }}>
            {children}
        </DataContext.Provider>
    );

}
export default DataContext;
