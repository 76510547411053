import styled from 'styled-components';
import BannerImg from '../../../assets/images/banner.png';

const BANNER_HEIGHT = '108px';

export const Container = styled.div`
    max-height: ${BANNER_HEIGHT};
    
    #banner--text {
        max-width: 400px;
        padding: 16px 0;
        
        position: relative;
        top: -${BANNER_HEIGHT};
    }

    @media (max-width: 756px) {
        max-height: none;

        #banner--text {
            position: static;
        }
    }
`;

export const HeaderTitle = styled.div`
    color: #004ea0 !important;
    font-size: 1.2em;
    font-weight: bold;
`;

export const HeaderDescription = styled.p`
    margin: 8px 0 0 0;
    max-width: 600px;
    font-weight: normal;
    font-size: 1.2rem;
    
    @media (max-width: 992px) {
        max-width: 380px;
    }
    
    @media (max-width: 460px) {
        max-width: 100%;
        font-size: 1rem;
    }
`;

export const HeaderBackgroundImg = styled.div`
    height: ${BANNER_HEIGHT};
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${BannerImg}) !important;
    background-color: #f5f5f5 !important;
`;
