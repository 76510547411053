import { useContext, useEffect, useState } from "react";
import {
  Button,
  Container as ContainerSemantic,
  Loader,
  Popup,
  Table,
} from "semantic-ui-react";
import { format } from "../../utils/format";
import AuthContext from "../../contexts/auth";
import dataProcessing from "../../utils/dataProcessing";
import { showSwal, swallDelete } from "../../utils/swal";
import { AiOutlineFileSearch } from "react-icons/ai";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "../../services/queryClient";
import { FooterWrapper, LinkUnderline } from "./styles";
import { bookletsRequest } from '../../services/booklets/bookletsRequest';
import { findBookletByIdRequest } from '../../services/booklets/findBookletRequest';
import { deleteBookletByIdRequest } from '../../services/booklets/deleteBookletByIdRequest';
import { ModalBooklet } from '../../components/Modal/ModalBooklet';
import { AxiosError } from 'axios';

const Booklets = () => {
  const { languageSelected } = useContext(AuthContext);
  const [indexLoading, setIndexLoading] = useState<number>();
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [selectedBookletId, setSelectedBookletId] = useState<number>();

  useEffect(() => {
    document.title = "GY TR Portal - Benefits";
  }, []);

  const { data: booklets } = useQuery(["BOOKLETS_LIST"], async () =>
    bookletsRequest()
  );

  const handleFormModalOpen = (id?: number) => {
    if (id) {
      setSelectedBookletId(id);
    }

    setIsFormModalOpen(true);
  }

  const handleFormModalClose = () => {
    setSelectedBookletId(undefined);
    setIsFormModalOpen(false);
  }

  const downloadBooklet = useMutation({
    mutationFn: async ({id, index}: any) => {
      setIndexLoading(index);
      const response = await findBookletByIdRequest(id);

      dataProcessing.toBinary(
        response?.file.byteArray,
        false,
        "application/pdf",
        response?.fileName
        );
      },
    onSuccess: () => {
      queryClient.invalidateQueries(['BOOKLETS_LIST']);
    },
    onError: (error: AxiosError<any>) => {
      showSwal(error.response?.data?.message, "error", "red");
      console.log('Error');
    },
    onSettled: () => {
      setIndexLoading(undefined);
    }
  });

  const deleteBooklet = useMutation({
    mutationFn: async ({id, index}: any) => {
      setIndexLoading(index);

      return swallDelete(languageSelected)
        .then(async (result) => {
          if (result.isConfirmed) {
            return deleteBookletByIdRequest(id);
          }
          return undefined;
        });
      },
    onSuccess: () => {
      showSwal(languageSelected.modal.swall.delete, "success", "green");
      queryClient.invalidateQueries(['BOOKLETS_LIST']);
    },
    onError: (error: AxiosError<any>) => {
      showSwal(error.response?.data?.message, "error", "red");
      console.log('Error');
    },
    onSettled: () => {
      setIndexLoading(undefined);
    }
  });

  return (
    <>
      <ContainerSemantic
        style={{ marginTop: "1em" }}
      >
        <Table striped stackable>
          <Table.Header>
            <Table.Row>
              {languageSelected?.modal?.semantic?.table?.header?.booklet?.map(
                (element: any) => {
                  return (
                    <Table.HeaderCell key={element} textAlign="center">
                      {element}
                    </Table.HeaderCell>
                  );
                }
              )}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {booklets &&
              booklets?.map((item, index) => (
                <Table.Row key={item.id}>
                  <Table.Cell textAlign="center">
                    <LinkUnderline
                      onClick={() => downloadBooklet.mutateAsync({id: item.id, index})}
                    >
                      {item.fileName}
                      <span style={{ marginLeft: "1rem" }}>
                        <Loader
                          active={downloadBooklet.isLoading && indexLoading === index}
                          inline
                          size="tiny"
                        />
                      </span>
                    </LinkUnderline>
                  </Table.Cell>

                  <Table.Cell textAlign="center" content={item.language} />

                  <Table.Cell textAlign="center" content={format.formatBlobSize(Number(item.size), 2)} />

                  <Table.Cell textAlign="center" content={format.formatDateToMonthDayYear(item.createdModified, languageSelected?.locales )} />

                  <Table.Cell textAlign="center" content={item.modifiedBy} />

                  <Table.Cell textAlign="center">
                    <Button.Group basic size="small">
                    <Popup
                      inverted
                      position="top left"
                      content={languageSelected?.modal?.semantic?.tooltip?.edit}
                      trigger={
                        <Button
                          basic
                          icon="edit"
                          onClick={() => handleFormModalOpen(item.id)}
                        />
                      }
                    />

                      <Popup
                        inverted
                        position="top left"
                        content={
                          languageSelected?.modal?.semantic?.tooltip?.open
                        }
                        trigger={
                          <Button
                            style={{ padding: "0" }}
                            onClick={() => downloadBooklet.mutateAsync({id: item.id, index})}
                            loading={downloadBooklet.isLoading && indexLoading === index}
                          >
                            <AiOutlineFileSearch
                              size="18"
                              style={{ margin: "7px" }}
                            />
                          </Button>
                        }
                      />
                      <Popup
                        inverted
                        position="top left"
                        content={
                          languageSelected.modal.semantic.tooltip.delete
                        }
                        trigger={
                          <Button
                            icon="trash"
                            onClick={() => deleteBooklet.mutateAsync({id: item.id, index})}
                            loading={
                              deleteBooklet.isLoading && indexLoading === index
                            }
                          />
                        }
                      />
                    </Button.Group>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>

          <Table.Footer
            style={{
              backgroundColor: "#ffffff",
              position: "sticky",
              bottom: 0,
            }}
          >
            <Table.Row>
              <Table.Cell colSpan="20">
                <FooterWrapper>
                  <span />
                  <Button onClick={() => handleFormModalOpen()}>
                    {languageSelected?.modal?.semantic?.tooltip?.register}
                  </Button>
                </FooterWrapper>
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </ContainerSemantic>

      {isFormModalOpen &&
        <ModalBooklet
          onModalClose={handleFormModalClose}
          bookletId={selectedBookletId}
        />
      }
    </>
  );
};
export default Booklets;
