import React, { useContext } from 'react'
import { Button, Popup } from 'semantic-ui-react';
import AuthContext from '../../../../../contexts/auth';

type IProps = {
    isUpdate?: boolean;
    handleClick(): void;
}

export function ButtonGroup({isUpdate, handleClick}: IProps) {
    const { languageSelected } = useContext(AuthContext);

    return isUpdate ? (
        <Popup
          inverted
          position="top left"
          content={languageSelected?.modal?.semantic?.tooltip?.edit}
          trigger={
            <Button
              basic
              icon="edit"
              onClick={handleClick}
            />
          }
        />
      ) : (
        <Button onClick={handleClick}>{languageSelected?.modal?.semantic?.tooltip?.register}</Button>
      );
}
