import { AxiosError } from 'axios';
import { BookletFormDataRequestProps } from '../../types/booklet';
import api from "../api";

export async function updateBookletRequest({ file }: BookletFormDataRequestProps) {
  try {
    const response = await api.put("/booklet", file, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

    return response.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      throw new Error(error.response?.data.message);
    }

    throw new Error();
  }
}
