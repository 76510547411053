import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Container as ContainerSemantic } from "semantic-ui-react";
import MyContext from "../../../contexts/auth";
import { Container, HeaderBackgroundImg, HeaderDescription, HeaderTitle } from './styles';

type LanguageData = {
  title: string;
  welcome: string;
  description: string;
};

type CurrentDataPage = Record<string, LanguageData>;

export const Banner = () => {
  const { languageSelected, user } = useContext(MyContext);
  const { pathname } = useLocation();


  const currentDataPage: CurrentDataPage = {
    "/web": {
      title: languageSelected?.title.home,
      welcome: `${languageSelected?.welcome} ${user?.name}`,
      description: `${languageSelected?.welcomeDescription} ${languageSelected?.welcomeDescription2}`,
    },
    "/communication": {
      title: languageSelected?.title.communication,
      welcome: `${languageSelected?.welcome} ${user?.name}`,
      description: `${languageSelected?.welcomeDescription} ${languageSelected?.welcomeDescription2}`,
    },
    "/adm": {
      title: languageSelected?.title.benefits,
      welcome: `${languageSelected?.welcome} ${user?.name}`,
      description: `${languageSelected?.welcomeDescription} ${languageSelected?.welcomeDescription2}`,
    },
    "/booklets": {
      title: languageSelected?.title.benefits,
      welcome: `${languageSelected?.welcome} ${user?.name}`,
      description: `${languageSelected?.welcomeDescription} ${languageSelected?.welcomeDescription2}`,
    },
  };

  return (
    <Container>
      <HeaderBackgroundImg />

      <ContainerSemantic id="banner--text">
        <HeaderTitle>{currentDataPage[pathname]?.welcome}</HeaderTitle>

        <HeaderDescription>
          {Object.keys(currentDataPage).find(path => path === pathname) && (
              currentDataPage[pathname]?.description
          )}
        </HeaderDescription>
      </ContainerSemantic>
    </Container>
  );
};
