import { AxiosResponse } from "axios";
import api from "../api";

export type BenefitsFormData = {
  countryId: number;
  employeeTypeId: number;
  name: string;
  createdModified: string;
  size?: string;
  modifiedBy: string;
  fileName: string;
};

type Response = Request & {
  id: number;
};

export async function saveBenefitRequest(
  token: string,
  data: BenefitsFormData
): Promise<Response> {
  try {
    const response: AxiosResponse<Response> = await api.post(
      "/benefits/",
      data,
      {
        params: { token },
        headers: {
          jwt: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }

    throw new Error();
  }
}
