import { useContext, useEffect, useState } from "react";
import {
  Button,
  Container as ContainerSemantic,
  Loader,
  Pagination,
  PaginationProps,
  Popup,
  Table,
} from "semantic-ui-react";
import { format } from "../../utils/format";
import AuthContext from "../../contexts/auth";
import { resourceApi } from "../../endpoints/resourceService";
import { ModalPdf } from "../../components/Modal/ModalBenefits";
import { URLS } from "../../types/env";
import dataProcessing from "../../utils/dataProcessing";
import DataContext from "../../contexts/data";
import { showSwal, swallDelete } from "../../utils/swal";
import { benefitsRequest } from "../../services/benefits/benefitsRequest";
import { deleteBenefitRequest } from "../../services/benefits/deleteBenefitRequest";
import { AiOutlineFileSearch } from "react-icons/ai";
import { useQuery } from "@tanstack/react-query";
import { queryClient } from "../../services/queryClient";
import { FooterWrapper, LinkUnderline } from "./styles";

const AdminPdf = () => {
  const { token, languageSelected } = useContext(AuthContext);

  const { setAtt } = useContext(DataContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [indexLoading, setIndexLoading] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    document.title = "GY TR Portal - Benefits";
  }, []);

  const { data: benefits } = useQuery(["BENEFITS_LIST", page], async () =>
    benefitsRequest(token.JWT, { page, size: 5 })
  );

  const handlePageChange = (
    _: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    { activePage }: PaginationProps
  ) => setPage(activePage as number);

  async function downloadPdf(item: any, index: string) {
    setIndexLoading(index);
    setLoading(true);

    if (!token.JWT) {
      window.location.href = URLS.BASE_URL;
      return
    }

    try {
      const response = await resourceApi.getPdf(token.JWT, true, item?.name);

      dataProcessing.toBinary(
        response?.byteArray,
        false,
        "application/pdf",
        response?.name
        );
      } catch (error) {
        showSwal('File not found', "error", "red");
        console.log('Error');
    } finally {
      setIndexLoading("");
      setLoading(false);
    }
  }

  async function deletePdf(item: any, index: string) {
    setIndexLoading(index);
    setLoading(true);
    await swallDelete(languageSelected).then(async (result) => {
      if (result.isConfirmed) {
        const data: any = await resourceApi.deleteFile(
          token.JWT,
          item.name,
          "PDF"
        );
        if (data === 200) {
          await deleteBenefitRequest(token.JWT, { id: item.id });
          showSwal(languageSelected.modal.swall.delete, "success", "green");
          setAtt(dataProcessing.randon());
          setIndexLoading("");
          setLoading(false);

          queryClient.invalidateQueries(["BENEFITS_LIST"]);
          return;
        }
        showSwal(languageSelected.modal.swall.nok, "error", "red");
        setIndexLoading("");
        setLoading(false);
      }
    });

    setLoading(false);
  }

  return (
    <>
      <ContainerSemantic
        className="benefits__content"
        style={{ marginTop: "1em" }}
      >
        <div>
          <Table striped stackable>
            <Table.Header>
              <Table.Row>
                {languageSelected?.modal?.semantic?.table?.header?.pdfAdmin?.map(
                  (element: any) => {
                    return (
                      <Table.HeaderCell key={element} textAlign="center">
                        {element}
                      </Table.HeaderCell>
                    );
                  }
                )}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {benefits &&
                benefits._embedded &&
                benefits?._embedded.benefits.map((item, index) => (
                  <Table.Row key={item.id}>
                    <Table.Cell textAlign="center">
                      <LinkUnderline
                        onClick={() => downloadPdf(item, `${index}`)}
                      >
                        {item.fileName}
                        <span style={{ marginLeft: "1rem" }}>
                          <Loader
                            active={loading && indexLoading === `${index}`}
                            inline
                            size="tiny"
                          />
                        </span>
                      </LinkUnderline>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {`${item.country?.countryCode} - ${item.country?.countryDescription} - ${item.country?.companyCode}`}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {item.employeeType?.employeeTypeDescription}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {format.formatDateToMonthDayYear(
                        item.createdModified,
                        languageSelected?.locales
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {item.modifiedBy}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {format.formatBlobSize(Number(item.size), 2)}
                    </Table.Cell>

                    <Table.Cell textAlign="center">
                      <Button.Group basic size="small">
                        <ModalPdf
                          data={item}
                          token={token.JWT}
                          isUpdate={true}
                        />
                        <Popup
                          inverted
                          position="top left"
                          content={
                            languageSelected?.modal?.semantic?.tooltip?.open
                          }
                          trigger={
                            <Button
                              style={{ padding: "0" }}
                              onClick={() => downloadPdf(item, `${index}`)}
                              loading={loading && indexLoading === `${index}`}
                            >
                              <AiOutlineFileSearch
                                size="18"
                                style={{ margin: "7px" }}
                              />
                            </Button>
                          }
                        />
                        <Popup
                          inverted
                          position="top left"
                          content={
                            languageSelected.modal.semantic.tooltip.delete
                          }
                          trigger={
                            <Button
                              icon="trash"
                              onClick={() => deletePdf(item, `delete${index}`)}
                              loading={
                                loading && indexLoading === `delete${index}`
                              }
                            />
                          }
                        />
                      </Button.Group>
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>

            <Table.Footer
              style={{
                backgroundColor: "#ffffff",
                position: "sticky",
                bottom: 0,
              }}
            >
              <Table.Row>
                <Table.Cell colSpan="20">
                  <FooterWrapper>
                    <Pagination
                      activePage={page}
                      totalPages={benefits?.page.totalPages ?? 0}
                      onPageChange={handlePageChange}
                    />

                    <ModalPdf token={token.JWT} isUpdate={false} />
                  </FooterWrapper>
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      </ContainerSemantic>
    </>
  );
};
export default AdminPdf;
