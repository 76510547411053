import { useContext, useMemo } from 'react'
import { InputFile } from '../../../../Form/InputFile';
import { FormInput } from '../../../../Form/FormInput';
import { CommunicationFormDataProps } from '../../../../../types/communication';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button, Form, Message } from 'semantic-ui-react';
import AuthContext from '../../../../../contexts/auth';
import { FormDropdown } from '../../../../Form/FormDropdown';
import { useFields } from '../../../../../hooks/useFields';
import { IFieldProps } from '../../../../../models/getFields';

type FormProps = {
  onSubmit(data: CommunicationFormDataProps): Promise<void | Response>
  onChangeFile(fileData?: File): void;
  onDeleteFile(): void;
  fields?: IFieldProps;
  currentFile?: File;
  errorFileType: boolean;
  isLoading: boolean;
}

export function Forms({
  onSubmit,
  onChangeFile,
  onDeleteFile,
  fields,
  currentFile,
  errorFileType,
  isLoading
}: FormProps) {
  const { control, register, handleSubmit, formState: { errors } } = useFormContext();
  const { languageSelected } = useContext(AuthContext);

  const watchCountries = useWatch({ control, name: "countryId" });
  const watchSubjectName = useWatch({ control, name: "subject" });
  const watchFileName = useWatch({ control, name: "fileName" });

  const { countriesOptions, employeeTypesCommunicationOptions, facilitiesOptions } =
    useFields(fields, watchCountries);

  const facilitiesFilteredOptions = useMemo(() => facilitiesOptions
  ?.map((item) => {
    const countrySelected = countriesOptions?.find(
      (country) => country.value === watchCountries
    );
    const location = countrySelected?.text
      .replaceAll(" ", "")
      .split("-")[0];

    if (location === item.text) {
      return {
        ...item,
        text: "All",
      };
    }
    return item;
  })
  .sort((a, b) => {
    if (a.text.toLocaleLowerCase() < b.text.toLocaleLowerCase()) {
      return -1;
    } else {
      return 1;
    }
  }), [countriesOptions, facilitiesOptions, watchCountries]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormInput<CommunicationFormDataProps>
        control={control}
        name="subject"
        label={languageSelected?.modal?.semantic?.table?.subject}
        required
        error={
          errors.subject?.type === "required" &&
          languageSelected?.required
        }
      />

      <FormInput<CommunicationFormDataProps>
        control={control}
        name="email"
        label={languageSelected?.modal?.semantic?.table?.email}
        required
        error={
          errors.email?.type === "required" && languageSelected?.required
        }
      />

      <FormInput<CommunicationFormDataProps>
        control={control}
        name="area"
        label={languageSelected?.modal?.semantic?.table?.area}
        required
        error={
          errors.area?.type === "required" && languageSelected?.required
        }
      />

      <FormInput<CommunicationFormDataProps>
        control={control}
        type='date'
        name="date"
        label={languageSelected?.modal?.semantic?.table?.dateOfMeeting}
        required
        error={
          errors.date?.type === "required" && languageSelected?.required
        }
      />

      <FormDropdown
        control={control}
        name="countryId"
        label={languageSelected?.modal?.semantic?.table?.companyCode}
        placeholder={`${languageSelected?.select} ${languageSelected?.modal?.semantic?.table?.companyCode}`}
        options={countriesOptions}
        required
        error={
          errors.countryId?.type === "required" &&
          languageSelected?.required
        }
      />

      <FormDropdown
        control={control}
        name="facilityId"
        label={languageSelected?.modal?.semantic?.table?.facility}
        placeholder={`${languageSelected?.select} ${languageSelected?.modal?.semantic?.table?.facilityPlaceholder}`}
        options={facilitiesFilteredOptions}
        required
        error={
          errors.facilityId?.type === "required" &&
          languageSelected?.required
        }
      />

      <FormDropdown
        control={control}
        name="employeeTypeId"
        label={languageSelected?.modal?.semantic?.table?.employeeType}
        placeholder={`${languageSelected?.select} ${languageSelected?.modal?.semantic?.table?.employeeType}`}
        options={employeeTypesCommunicationOptions?.sort((a, b) => {
          if (a.text < b.text) {
            return -1;
          } else {
            return 1;
          }
        })}
        required
        error={
          errors.employeeTypeId?.type === "required" &&
          languageSelected?.required
        }
      />

      <InputFile
        file={currentFile}
        required
        watchFileName={watchFileName}
        label={languageSelected?.modal?.semantic?.table?.file}
        accept={["image/*, application/pdf"]}
        placeholder={
          languageSelected?.modal?.semantic?.table?.filePlaceholder
        }
        onInvalid={(e: any) =>
          (e.target as HTMLInputElement).setCustomValidity(
            languageSelected?.required
          )
        }
        {...register("fileName")}
        onChange={(e) => onChangeFile(e.currentTarget.files?.[0])}
        onDeleteFile={onDeleteFile}
        fluid
        disabled={!watchSubjectName}
      />

      {errorFileType && (
        <Message error>
          <Message.Header>
            {languageSelected?.modal?.semantic?.table?.error.fileType}
          </Message.Header>
        </Message>
      )}

      <p>
        {
          languageSelected?.modal?.semantic?.table
            ?.fileTypesMessageCommunication
        }
      </p>

      <Button
        style={{ width: "100%" }}
        type="submit"
        loading={isLoading}
        disabled={errorFileType}
      >
        {languageSelected?.send}
      </Button>
    </Form>
  )
}
