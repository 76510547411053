import axios from 'axios';
import { CompaniesModel } from '../models/Companies';
import { ResourceModel } from '../models/ResourceModel';
import { URLS } from "../types/env";
import { format } from '../utils/format';

const urlEndpoint = URLS.URL_ENDPOINT;

const getRequestOptions = (tokenRequest: string) => {
    return {
        headers: {
            "jwt": tokenRequest
        }
    };
}

const getAllPdf = async (tokenRequest: string, byteArrayIncluded: boolean): Promise<any> => {
    let errorReturn;
    const resources = await axios.get(
        `${urlEndpoint}/resources/allFiles?token=${tokenRequest}&folderName=PDF/&byteArrayIncluded=${byteArrayIncluded}`,
        getRequestOptions(tokenRequest)).catch((error) => {
            if (error.response) {
                if (error.response.data.businessCode) {
                    errorReturn = error.response.data.businessCode;
                }
            }
        });
    return errorReturn ? errorReturn : resources?.data;
}

const getAllFilesCommunication = async (tokenRequest: string, byteArrayIncluded: boolean): Promise<any> => {
    let errorReturn;
    const resources = await axios.get(
        `${urlEndpoint}/resources/allFiles?token=${tokenRequest}&folderName=COMMUNICATION/&byteArrayIncluded=${byteArrayIncluded}`,
        getRequestOptions(tokenRequest)).catch((y) => {
            if (y.response) {
                if (y.response.data.businessCode) {
                    errorReturn = y.response.data.businessCode;
                }
            }
        });
    return errorReturn ? errorReturn : resources?.data;
}

const getPdf = async (tokenRequest: string, byteArrayIncluded: boolean, fileName: string): Promise<any> => {
    let errorReturn;
    const resources = await axios.get(
        `${urlEndpoint}/resources?token=${tokenRequest}&folderName=PDF&fileName=${fileName}&byteArrayIncluded=${byteArrayIncluded}`,
        getRequestOptions(tokenRequest)
    ).catch((xs) => {
        if (xs.response) {
            if (xs.response.data.businessCode) {
                errorReturn = xs.response.data.businessCode;
            }
        }
    });
    return errorReturn ? errorReturn : resources?.data;
}
const getFile = async (tokenRequest: string, byteArrayIncluded: boolean, fileName: string, folderName: string): Promise<any> => {
    let errorReturn;
    const resources = await axios.get(
        `${urlEndpoint}/resources?token=${tokenRequest}&path=${folderName}&fileName=${fileName}&byteArrayIncluded=${byteArrayIncluded}`,
        getRequestOptions(tokenRequest)
    ).catch((x) => {
        if (x.response) {
            if (x.response.data.businessCode) {
                errorReturn = x.response.data.businessCode;
            }
        }
    });
    return errorReturn ? errorReturn : resources?.data;
}

const updateFile = async (tokenRequest: string, formData: FormData): Promise<any> => {
    let result = {
        status: 0
    };

    await axios.put(
        `${urlEndpoint}/resources?token=${tokenRequest}`, formData, getRequestOptions(tokenRequest)).then(() => {
            result.status = 201;
        }).catch((err) => {
            if (err.response) {
                result = err.response;
            }
        });
    return result;
}

const createPdf = async (tokenRequest: string, formData: FormData): Promise<any> => {
    let result;
    await axios.post(
        `${urlEndpoint}/resources/pdf`, formData, getRequestOptions(tokenRequest)).then((_e) => {
            result = 201;
        }).catch((er) => {
            if (er.response) {
                if (er.response.data.businessCode) {
                    result = er.response.data.businessCode;
                }
                result = er.response.status;
            }
        });

    return result;
}

const createFile = async (tokenRequest: string, formData: FormData): Promise<any> => {
    let result = {
        status: 0
    };

    await axios.post(
        `${urlEndpoint}/resources?token=${tokenRequest}`, formData,
        getRequestOptions(tokenRequest)).then((_e) => {
            result.status = 201;
        }).catch((err) => {
            if (err.response) {
                result = err.response;
            }
        });

    return result;
}

type SendInfoSaveFileRequest = {
    area: string,
    company: string,
    typeOne: string,
    email: string,
    subject: string,
    typeArquive: string,
    emailUser: string,
    typeJob: string,
    user: string,
    country: string,
    date: string,
    facilityCode: string,
    fileName: string
}

const sendInfoSaveFile = async (token: string, data: SendInfoSaveFileRequest): Promise<any> => {
    let result;
    await axios.post(`${urlEndpoint}/communication?token=${token}`, data,
    getRequestOptions(token)).then((_e) => {
        result = 201;
    }).catch((erx) => {
        if (erx.response) {
            if (erx.response.data.businessCode) {
                result = erx.response.data.businessCode;
            }
            result = erx.response.status;
        }
    });

    return result;
}


const dataPdf = async (token: string, bytes: boolean): Promise<any> => {

    if (token) {
        const data: any = await resourceApi.getAllPdf(token, bytes);
        if (data === "105" || data === "102") {
            window.location.href = URLS.BASE_URL;
        }
        const response: Array<ResourceModel> = data;
        return (response.sort((a, b) => format.formatFileName(a.name).localeCompare(format.formatFileName(b.name))));
    }
    return undefined;
}

const deleteFile = async (tokenRequest: string, fileName: string, folderName: string) => {
    let result;
    await axios.delete(
        `${urlEndpoint}/resources?token=${tokenRequest}&folderName=${folderName}&fileName=${fileName}`,
        getRequestOptions(tokenRequest)).then(() => {
            result = 200;
        }).catch((e) => {
            if (e.response) {
                if (e.response.data.businessCode) {
                    result = e.response.data.businessCode;
                }
            }
        });
    return result;
}
const getCompanies = async (tokenRequest: string): Promise<CompaniesModel> => {
    const companies = await axios.get(
        `${urlEndpoint}/resources/companies?token=${tokenRequest}&folderName=JSON`,
        getRequestOptions(tokenRequest));
    return companies.data;
}

const getFacilities = async (tokenRequest: string): Promise<any> => {
    const locales = await axios.get(
        `${urlEndpoint}/resources/facilities?token=${tokenRequest}&folderName=JSON`,
        getRequestOptions(tokenRequest));
    return locales.data;
}

export const resourceApi = {
    getAllPdf,
    updateFile,
    getPdf,
    dataPdf,
    deleteFile,
    getCompanies,
    createPdf,
    getAllFilesCommunication,
    getFile,
    createFile,
    sendInfoSaveFile,
    getFacilities
};
