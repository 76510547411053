import {
  CommunicationResponseDataProps,
  CommunicationProps,
} from "../../types/communication";
import api from "../api";

interface IProps {
  token: string;
  page?: number;
  subject?: string;
  totalPages?: number;
  size?: number;
}

interface ResponseDataProps {
  number: number;
  size: number;
  totalElements: number;
  totalPages?: number;
  content: CommunicationProps[];
}

export async function getListCommunicationRequest({
  token,
  page = 1,
  subject,
  totalPages,
}: IProps): Promise<CommunicationResponseDataProps> {
  try {
    const response = await api.get("/communication/", {
      params: {
        token,
        page: page - 1,
        totalPages,
        size: 5,
        subject: subject ? subject : undefined,
        sort: "date,desc",
      },
      headers: {
        jwt: token,
      },
    });

    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }

    throw new Error();
  }
}
