import { useMutation } from '@tanstack/react-query';
import { CommunicationFormDataProps } from '../../../types/communication';
import { editCommunicationRequest } from '../../../services/communication/editCommunicationRequest';
import { format } from '../../../utils/format';
import { showSwal } from '../../../utils/swal';
import { queryClient } from '../../../services/queryClient';
import { useContext } from 'react';
import AuthContext from '../../../contexts/auth';
import { useFormContext } from 'react-hook-form';
import { INITIAL_VALUES } from '../constants';

type Props = {
  handleFormModalClose(): void;
  fileType: string;
  currentFile?: File;
  watchFileName?: string;
}

export const useUpdateCommunication = ({ handleFormModalClose, currentFile, fileType, watchFileName }: Props) => {
  const { languageSelected, user, token } = useContext(AuthContext);
  const { reset } = useFormContext();

  const updateCommunication = useMutation({
    mutationFn: async (data: CommunicationFormDataProps) => {
      if (user) {
        return editCommunicationRequest(token.JWT, {
          ...data,
          area: format.normalize(data?.area ?? ""),
          typeArquive: fileType ? fileType : data.typeArquive,
          fileName: currentFile?.name ?? watchFileName,
          user: `${user.name} ${user.lastName}`,
        })
      } else {
        showSwal(languageSelected?.modal?.swall?.username, "error", "red");
      }

      return undefined
    },
    onSuccess: () => {
      handleFormModalClose();
      showSwal(languageSelected?.modal?.swall?.created, "success", "green");
    },
    onError: () => {
      showSwal(languageSelected?.modal?.swall?.conflict, "error", "red");
    },
    onSettled: (_res, _err, data) => {
      reset({
        ...INITIAL_VALUES,
        emailUser: data?.emailUser,
        user: data?.user,
      });

      queryClient.invalidateQueries(["COMMUNICATION_LIST"]);
    }
  });

  return {
    updateCommunication
  }
};
