import axios from 'axios';

import cookies from '../utils/cookies';
import { URLS } from '../types/env';

const api = axios.create({
  baseURL:  URLS.URL_ENDPOINT,
  headers: {
		'Content-Type': 'application/json'
	},
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const token = cookies.getCookie('@TOKEN');
    
    if (axios.isCancel(error)) {
      return console.log(error);
    }

		const businessCode = Number(error.response?.data?.businessCode);

    if (businessCode === 100 && token) {
			api.defaults.headers.common.jwt = token;
      api.defaults.params = { token }    
		}

    if ([100, 102, 105].includes(businessCode)) {
			delete api.defaults.headers.common.jwt;
      delete api.defaults.params.token;  

			cookies.removeCookie('@TOKEN');
			cookies.removeCookie('@USER');
      window.location.href = URLS.URL_LOGIN;
		}


    return Promise.reject(error);
  }
);

export default api;
