import { Button, Form, Header, Modal } from "semantic-ui-react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { resourceApi } from "../../../endpoints/resourceService";
import DataContext from "../../../contexts/data";
import AuthContext from "../../../contexts/auth";
import { showSwal } from "../../../utils/swal";
import dataProcessing from "../../../utils/dataProcessing";
import { FileUpload } from "../../../models/FileUpload";
import { getBenefitsFieldRequests } from "../../../services/benefits/benefitsFieldRequest";
import { IFieldProps } from "../../../models/getFields";
import { updateBenefitRequest } from "../../../services/benefits/updateBenefitRequest";
import {
  BenefitsFormData,
  saveBenefitRequest,
} from "../../../services/benefits/saveBenefitRequest";
import { useFields } from "../../../hooks/useFields";
import { Benefit } from "../../../services/benefits/benefitsRequest";
import { queryClient } from "../../../services/queryClient";
import { FormDropdown } from '../../Form/FormDropdown';
import { filterCompany } from './utils/filterCompany';
import { ButtonGroup } from './components/ButtonGroup';

interface ModalPdfProps {
  data?: Benefit;
  token: string;
  isUpdate?: boolean;
}

export const ModalPdf = ({ data, token, isUpdate }: ModalPdfProps) => {
  const [open, setOpen] = useState(false);
  const { setAtt, companies } = useContext(DataContext);
  const { languageSelected, user } = useContext(AuthContext);
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<BenefitsFormData>();
  const [loading, setLoading] = useState(false);
  const userEdit = user && `${user?.name} ${
    user?.lastName?.charAt(0) + user?.lastName?.slice(1)?.toLocaleLowerCase()
  }`;
  const [fields, setFields] = useState<IFieldProps>();
  const [errorFileType, setErrorFileType] = useState<boolean>(false);
  const [file, setFile] = useState<File>();

  useEffect(() => {
    getBenefitsFieldRequests({ token }).then((res) => {
      setFields(res);
    });
  }, []);

  const { countriesOptions, employeeTypesPdfOptions } = useFields(fields);

  const update = async (values: BenefitsFormData) => {
    setLoading(true);

    const name = data?.name;
    const company = filterCompany(companies, name as string);
    const fileUpload: FileUpload = toFileUpload(name as string, company, file);

    const result = await resourceApi.updateFile(
      token,
      dataProcessing?.mountFormData(fileUpload)
    );
    await updateBenefitRequest(token, {
      id: data?.id as number,
      countryId: data?.country?.id as number,
      employeeTypeId: data?.employeeType?.id as number,
      name: data?.fileName as string,
      fileName: data?.fileName as string,
      createdModified: new Date().toISOString(),
      size: file?.size,
      modifiedBy: userEdit as string,
    });

    setLoading(false);

    if (result.status === 201) {
      showSwal(languageSelected?.modal?.swall?.edit, "success", "green");
      queryClient.invalidateQueries(["BENEFITS_LIST"]);
      setAtt(dataProcessing.randon());
      reset();
    } else {
      showSwal(result?.data?.message, "error", "red");
    }

    setOpen(false);
    reset();
  };

  const toFileUpload = (name: string, company: string, multipartFile: any) => {
    return {
      path: "PDF",
      fileName: name,
      tag: ["v1"],
      tagValues: ["v1"],
      metadata: ["region", "userEdit"],
      metadatasValues: [
        company[0]?.split("-")[1]?.replaceAll(" ", ""),
        userEdit,
      ],
      multipartFile: multipartFile,
    };
  };

  const countryIdWatch = useWatch({ control, name: "countryId" });
  const countryCode = useMemo(() => {
    return countriesOptions
      ?.find((country) => country.key === countryIdWatch)
      ?.text.split(" - ")
      .at(-1);
  }, [countriesOptions, countryIdWatch]);
  const employeeTypeIdWatch = useWatch({ control, name: "employeeTypeId" });
  const employeeTypeInitialLetter = useMemo(() => {
    return employeeTypesPdfOptions?.find(
      (employeeType) => employeeType.key === employeeTypeIdWatch
    )?.text[0];
  }, [employeeTypesPdfOptions, employeeTypeIdWatch]);

  const create = async (values: BenefitsFormData) => {
    setLoading(true);
    const name = `${countryCode}-${employeeTypeInitialLetter}-Benefits.pdf`;
    const company = filterCompany(companies, name);
    const fileUpload: FileUpload = toFileUpload(name, company, file);
    const sendRequest = dataProcessing.mountFormData(fileUpload);
    const result = await resourceApi.createFile(token, sendRequest);

    if (result.status === 201) {
      await saveBenefitRequest(token, {
        countryId: values?.countryId,
        employeeTypeId: values?.employeeTypeId,
        name,
        createdModified: new Date().toISOString(),
        size: file?.size ? String(file.size) : "1",
        modifiedBy: userEdit as string,
        fileName: name,
      });
    }
    setLoading(false);

    if (result.status === 201) {
      showSwal(languageSelected?.modal?.swall?.created, "success", "green");
      queryClient.invalidateQueries(["BENEFITS_LIST"]);
      setAtt(dataProcessing.randon());
      reset();
    } else  {
      showSwal(result?.data?.message, "error", "red");
    }
    setOpen(false);
    reset();
  };

  function onChangeFile(fileData?: File) {
    setErrorFileType(false);
    if (fileData?.type !== "application/pdf") {
      return setErrorFileType(true);
    }
    return setFile(fileData);
  }

  const inputFile = () => {
    return (
      <Form.Input
        type="file"
        accept={["application/pdf"]}
        required
        onChange={(e) => onChangeFile(e.currentTarget.files?.[0])}
        error={
          errorFileType && languageSelected?.modal?.semantic?.table?.error.fileType
        }
      />
    );
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon
      size="small"
      trigger={<ButtonGroup isUpdate={isUpdate} handleClick={() => setOpen((oldValue) => !oldValue)} />}
    >
      <Modal.Header>
        { isUpdate ?
          data?.name :
          languageSelected?.modal?.semantic?.table?.ModalBenefitsTitle
        }
      </Modal.Header>
      <Modal.Content image scrolling>
        <Modal.Description>
          <Form
            onSubmit={handleSubmit((item: BenefitsFormData) =>
              isUpdate ? update(item) : create(item)
            )}
          >
            <Header />

            {!isUpdate &&
              <>
                <FormDropdown
                  control={control}
                  name="countryId"
                  required={true}
                  label={languageSelected?.modal?.semantic?.table?.companyCode}
                  placeholder={`${languageSelected?.select} ${languageSelected?.modal?.semantic?.table?.companyCode}`}
                  options={countriesOptions}
                  error={errors.countryId?.type === "required" && languageSelected?.required}
                />

                <FormDropdown
                  control={control}
                  name="employeeTypeId"
                  required={true}
                  label={languageSelected?.modal?.semantic?.table?.employeeType}
                  placeholder={`${languageSelected?.select} ${languageSelected?.modal?.semantic?.table?.employeeType}`}
                  options={employeeTypesPdfOptions}
                  error={errors.employeeTypeId?.type === "required" && languageSelected?.required}
                  search
                />
              </>
            }

            <b>{languageSelected?.modal?.semantic?.table?.file}</b>
            {inputFile()}
            <p>
              {
                languageSelected?.modal?.semantic?.table
                  ?.fileTypesMessageBenefits
              }
            </p>
            <Button
              style={{ width: "100%" }}
              type="submit"
              loading={loading}
            >
              {languageSelected?.send}
            </Button>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
