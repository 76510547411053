import api from "../api";

export async function deleteBookletByIdRequest(id: number) {
  try {
    const response = await api.delete(`/booklet/${id}`);

    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }

    throw new Error();
  }
}
