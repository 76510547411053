import styled from 'styled-components';
import { CardImageProps } from '.';

type CardStyledProps = {
    isAuthorized?: boolean;
}

type ContainerProps = {
    isAuthorized?: boolean;
}

type CardGroupProps = {
    countCards: number;
}

export const Container = styled.div<ContainerProps>`
    min-height: 130px;
    max-height: 200px;
    min-width: 380px;
    max-width: 555px;
    /* flex: 1; */
    flex-basis: 555px;
    outline: 1px solid rgba(0, 0, 0, .2);
    border-radius: 5px;
    position: relative;

    display: ${({isAuthorized})=> isAuthorized ? 'flex' : 'none'};

    overflow: hidden;
    transition: all ease .2s;
    cursor: ${({ isAuthorized }) => isAuthorized && 'pointer'};
    
    .hidden {
        opacity: 0;
        transition: all ease .2s;
    }

    &:hover {
        transform: ${({ isAuthorized }) => isAuthorized && 'scale(1.01)'};
        margin-top: ${({ isAuthorized }) => isAuthorized && '-2px'};
        box-shadow: ${({ isAuthorized }) => isAuthorized && '0 4px 6px -2px rgba(0, 0, 0, 0.2)'};
        .hidden {
            opacity: .8;
        }
    }

    @media (max-width: 460px) {
        height: 150px;
        min-width: 100%;
    }
`;

export const CardGroupStyled = styled.div<CardGroupProps>`
    margin: 14px auto;
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    flex: 1;

    ${({ countCards }) => countCards > 4 && `
        min-height: 100%;
    `}

    @media (max-height: 836px) {
        min-height: 100%;
    }
`;

export const CardStyled = styled.div<CardStyledProps>`
    border-radius: 5px;

    display: flex;
    flex-wrap: nowrap;
    flex: 1;
    
    filter: ${({ isAuthorized }) => !isAuthorized && 'grayscale()'};
    transition: all ease .3s;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    &:hover {
        filter: ${({ isAuthorized }) => !isAuthorized && `
            grayscale() blur(1px);
            cursor: not-allowed;
        `};
    }
`;

export const CardImage = styled.div<CardImageProps>`
    background-image: ${({ src }) => `url(${src})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 120px;
    min-width: 120px;
    flex: 1;

    margin: 1em;
    border-radius: 5px;
    
    @media (max-width: 460px) {
        margin: 0 8px 0 0;
        width: 80px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
`;

export const CardHeaderStyled = styled.div`
    font-weight: bold;
    font-size: 1.28571429em;
    margin-top: -0.21425em;
    line-height: 1.28571429em;
    color: #004ea0 !important;

    @media (max-width: 460px) {
        font-size: 1em;
    }
`;

export const CardContentStyled = styled.div`
    color: rgba(0,0,0,.68);
    clear: both;
    margin: 5px 0;
    padding: 1em 1em 1em 0;

    display: flex;
    flex-direction: column;
    flex: 2;

    position: relative;

    @media (max-width: 460px) {
        padding: 8px;
        padding: 8px 8px 8px 0;
    }
`;

export const CardDescriptionStyled = styled.div`
    min-height: 0;
    margin-top: 0.5em;

    flex: 1;
    
    overflow-y: auto;
`;

export const CardFooterStyled = styled.div`
    padding-top: 1em;
    border-top: 1px solid rgba(0, 0, 0, .2);
`;

export const CardUnauthorized = styled.div`
    background-color: #930000;
    color: #ffffff;
    font-weight: bold;
    font-size: 1.4em;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 400px;
    height: 30px;

    transform: rotate(45deg);
    position: absolute;

    top: 50%;
    left: 15%;
    margin-top: -15px;
    margin-left: -200px;
`;
