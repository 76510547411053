import api from "./api";

function getFileFormData(file: File, path: string) {
  const formData = new FormData();

  formData.append("path", path);
  formData.append("fileName", file.name);
  formData.append("multipartFile", file);

  return formData;
}

export async function uploadFileService(
  token: string,
  file: File,
  path: string
): Promise<Response> {
  const fileFormData = getFileFormData(file, path);

  const response = await api.post("/communication/upload", fileFormData, {
    params: {
      token,
    },
    headers: {
      "Content-Type": "multipart/form-data",
      jwt: token,
    },
  });

  return response.data;
}

export async function deleteFileService(
  token: string,
  folderName: string,
  fileName: string
): Promise<Response> {
  const response = await api.delete("/resources", {
    params: {
      token,
      folderName,
      fileName,
    },
    headers: {
      jwt: token,
    },
  });

  return response.data;
}

export async function downloadFileService(
  token: string,
  fileName: string,
  folderName: string
) {
  const response = await api.get("/resources", {
    params: {
      token,
      folderName,
      byteArrayIncluded: true,
      fileName,
    },
    headers: {
      jwt: token,
    },
  });

  return response.data;
}
