import styled from "styled-components";

export const Container = styled.div`
  .ui.menu {
    border-radius: 0 !important;

    .container.header-menu-container {
      padding: 16px 0;
    }
  } 

  .header-menu--left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;

    a {
      img.ui.image {
        height: auto;
        max-width: 100%;
      }
    }

    .titleHeader {
      display: none;
    }

    .header-menu-change-language {
      height: auto;

      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  .header-menu--right {
    display: flex;
    flex-direction: column;
    align-items: end;

    
    img.ui.image {
        height: auto;
    }

    .header-icons {
      margin: 0 16px;

      display: flex;
      flex-direction: row;
      gap: 16px;
      
      position: relative;
      bottom: 119px;
      right: 188px;

      .ui.inline.dropdown {
        display: flex;
        align-items: center;
      }

      i {
        display: block;
      }

      .header-menu-languages {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .ui.inverted.menu {
    height: 135px !important;
    background: #004ea0 !important;
    margin: 0px;
  }

  @media (max-width: 600px) {
    .header-menu--left {      
      a {
        img.ui.image {
          height: 40px;
          max-width: auto;
        }
      }
    }
  }

  @media (max-width: 756px) {
    .ui.image {
      height: 38px;
    }
  }

  @media (max-width: 768px) {
    .ui.inverted.menu {
      height: auto !important;
    }
    
    .ui.menu > .container.header-menu-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 16px;
      padding: 16px;
    }
    
    .header-menu--left {
      align-items: center;
      gap: 4px;

      .titleHeader {
        width: 100%;
        display: block;
        font-size: 18px;
        font-weight: bold;
        color: #FFFFFF;
        text-align: center;
      }
    }

    .header-menu--right {
      align-items: center;

      .ui.image {
        display: none;
      }

      .header-icons {
        position: static;
      }
    }
  }

  @media (max-width: 990px) {
    .header-menu--right {
      .header-icons {
        bottom: 62px !important;
        right: 124px !important;
      }
    }
  }

  @media (max-width: 1200px) {
    .header-menu img.ui.image {
      width: 100%;
    }

    .header-menu--right {
      .header-icons {
        bottom: 94px;
        right: 158px;
      }

    }
  }  
`;
