import { IFieldProps } from "../../models/getFields";
import api from "../api";

interface IProps {
  token: string;
  id?: number;
}

export async function getCommunicationFieldRequests({
  token,
}: IProps): Promise<IFieldProps> {
  try {
    const response = await api.get(`/communication/dropdown`, {
      params: { token },
      headers: {
        jwt: token,
      },
    });

    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }

    throw new Error();
  }
}
