const checkConditionDataValid = (dados:any) : boolean  => {
    return dados ? true : false;
}

const checkConditions = (firstComparator : any, lastComparator : any , returnIsTrue : string , returnIsFalse : string ) :string  =>{
    if(firstComparator === undefined){
        firstComparator = false;
    }
    return firstComparator === lastComparator ? returnIsTrue : returnIsFalse;
}

const invalidMsg = (textbox: any, required:any) => {
    textbox.target.setCustomValidity(required);
    return true;
}

export const checkCondition = {
    checkConditionDataValid,
    checkConditions,
    invalidMsg
}
