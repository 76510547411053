import styled from "styled-components";

export const LinkUnderline = styled.a`
  text-align: left;

  background: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 900px) {
    display: block;
    text-align: center;

    button {
      margin-top: 20px !important;
    }
  }
`;
