import { Container as ContainerSemantic } from 'semantic-ui-react';
import styled from 'styled-components';

export const Container = styled(ContainerSemantic)`
    height: 100% !important;
    
    @media (min-height: 960px) {
        height: auto !important;
    }
`;
