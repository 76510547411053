import { useMemo } from "react";
import { IFieldProps } from "../models/getFields";

export const useFields = (data?: IFieldProps, watchCountries?: number) => {
  const countriesOptions = data?.countries?.map((country) => ({
    key: country.id,
    text: [country.countryCode, country.countryDesc, country.companyCode].join(
      " - "
    ),
    value: country.id,
  }));

  const employeeTypesPdfOptions = data?.employeeTypes
    ?.filter((el) => el.employeeTypeDescription !== "All")
    .map((employeeType) => ({
      key: employeeType.id,
      text: employeeType.employeeTypeDescription,
      value: employeeType.id,
    }));

  const employeeTypesCommunicationOptions = data?.employeeTypes?.map((employeeType) => ({
    key: employeeType.id,
    text: employeeType.employeeTypeDescription,
    value: employeeType.id,
  }));

  const facilitiesOptions = useMemo(() => {
    const facilitiesFiltered = data?.facilities?.filter(
      (e: any) => e.countryId === watchCountries
    );
    return facilitiesFiltered?.map((facility) => ({
      key: facility.id,
      text: facility.facilityDescription,
      value: facility.id,
    }));
  }, [data?.facilities, watchCountries]);

  return {
    countriesOptions,
    employeeTypesPdfOptions,
    employeeTypesCommunicationOptions,
    facilitiesOptions,
  };
};
