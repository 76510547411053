export const filterCompany = (companies: any, name: string): string => {
  return companies?.codes?.filter((e: any) => {
    const element = e?.split("-")[0]?.replaceAll(" ", "");
    const elementComparator = name?.split("-")[0];
    if (element === elementComparator) {
      return e;
    }
    return "";
  });
};

