import { AxiosResponse } from "axios";
import api from "../api";

type Params = {
  page: number;
  size: number;
};

export type Benefit = {
  id: number;
  country: {
    id: number;
    countryCode: string;
    companyCode: string;
    countryDescription: string;
  };
  employeeType: {
    id: number;
    employeeTypeCode: string;
    employeeTypeDescription: string;
  };
  name: string;
  createdModified: string;
  size?: string;
  modifiedBy: string;
  fileName: string;
  locale: string;
};

export type Response = {
  _embedded: {
    benefits: Benefit[];
  };
  page: {
    size: number;
    totalElements: number;
    totalPages: number;
    number: number;
  };
};

export async function benefitsRequest(
  token: string,
  { page }: Params
): Promise<Response> {
  try {
    const response: AxiosResponse<Response> = await api.get("/benefits/", {
      params: {
        token,
        page: page - 1,
        size: 5,
      },
      headers: {
        jwt: token,
      },
    });
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }

    throw new Error();
  }
}
