import api from "../api";

type Request = {
    id: number;
};

export async function deleteBenefitRequest(token: string, { id }: Request): Promise<void> {
  try {
    await api.delete(`/benefits/${id}`, {
      params: { token },
      headers: {
        "jwt": token
      }
    });
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }

    throw new Error();
  }
}
