import { Modal, ModalProps } from "semantic-ui-react";
import { useContext, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import moment from "moment";

import AuthContext from "../../../contexts/auth";
import {
  deleteFileService,
  uploadFileService,
} from "../../../services/fileService";
import { CommunicationFormDataProps } from "../../../types/communication";
import { getCommunicationRequest } from "../../../services/communication/getCommunicationRequest";
import { INITIAL_VALUES } from "../../../pages/Communication/constants";
import { getCommunicationFieldRequests } from "../../../services/communication/communicationFieldRequest";
import { Forms } from './components/Form';
import { IFieldProps } from '../../../models/getFields';
import { useUpdateCommunication } from '../../../pages/Communication/hooks/updateCommunication';
import { useCreateCommunication } from '../../../pages/Communication/hooks/createCommunication';

interface IProps extends ModalProps {
  communicationId?: number;
  isUpdate: boolean;
  handleFormModalClose(): void;

  currentFile?: File;
  setCurrentFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  isDeleteFile: boolean;
  setIsDeleteFile: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalCommunication = ({
  communicationId,
  isUpdate,
  handleFormModalClose,

  currentFile,
  setCurrentFile,
  isDeleteFile,
  setIsDeleteFile,
  ...rest
}: IProps) => {
  const { control, reset, setValue } = useFormContext();
  const { token, languageSelected, user } = useContext(AuthContext);
  const [errorFileType, setErrorFileType] = useState<boolean>(false);
  const [fields, setFields] = useState<IFieldProps>();
  const [oldFileName, setOldFileName] = useState<string>();
  const [fileType, setFileType] = useState("");

  const watchSubjectName = useWatch({ control, name: "subject" });
  const watchFileName = useWatch({ control, name: "fileName" });

  useEffect(() => {
    if (communicationId) {
      getCommunicationRequest({ token: token.JWT, id: communicationId })
        .then((res) => {
          setOldFileName(res?.fileName);
          reset({
            id: res?.id,
            subject: res?.subject,
            area: res?.area,
            typeArquive: res?.typeArquive,
            user: res?.user,
            email: res?.email,
            emailUser: res?.emailUser,
            fileName: res?.fileName,
            date: res?.date,
            countryId: res.countries.id,
            employeeTypeId: res.employeeType.id,
            facilityId: res.facilities.id,
          });
        });
    } else {
      reset({
        ...INITIAL_VALUES,
        emailUser: user?.email,
        user: user?.name,
      });
    }

    getCommunicationFieldRequests({ token: token.JWT }).then((res) => {
      setFields(res);
    });
  }, [token, communicationId, reset, user?.name, user?.email]);

  const { createCommunication } = useCreateCommunication({
    currentFile, fileType, handleFormModalClose
  });

  const { updateCommunication } = useUpdateCommunication({
    currentFile, fileType, handleFormModalClose, watchFileName
  });

  const onChangeFile = (fileData?: File) => {
    setErrorFileType(false);
    const fileName = `${watchSubjectName}_${moment().format(
      "YYYYMMDD"
    )}_${moment().format("hhmmss")}.${fileData?.name.split(".").at(-1)}`;
    const blob = fileData?.slice(0, fileData?.size, fileData.type);

    const newFile = new File([blob as Blob], fileName);

    if (
      fileData?.type.split("/")[0] === "image" ||
      fileData?.type === "application/pdf"
    ) {
      setFileType(fileData.type);
      setCurrentFile(newFile);
      setValue("fileName", fileName);
      return;
    }

    setErrorFileType(true);
  };

  const onDeleteFile = () => {
    setIsDeleteFile(true);
    setCurrentFile(undefined);
    setValue("fileName", "");
  };

  const uploadCommunicationFile = async (fileUpload: File) => {
    return uploadFileService(token.JWT, fileUpload, "COMMUNICATION");
  };

  const onSubmit = async (data: CommunicationFormDataProps) => {
    if (currentFile && currentFile?.name !== oldFileName) {
      await uploadCommunicationFile(currentFile);
    }

    if (isDeleteFile && oldFileName) {
      await deleteFileService(token.JWT, "COMMUNICATION", oldFileName);
    }

    if (communicationId) {
      return updateCommunication.mutateAsync(data);
    }

    return createCommunication.mutateAsync(data);
  };

  return (
    <Modal closeIcon size="small" {...rest}>
      <Modal.Header>
        {languageSelected?.modal?.semantic?.table?.ModalCommunicationTitle}
      </Modal.Header>
      <Modal.Content>
        <Forms
          onSubmit={onSubmit as any}
          onChangeFile={onChangeFile}
          onDeleteFile={onDeleteFile}
          currentFile={currentFile}
          fields={fields}
          errorFileType={errorFileType}
          isLoading={createCommunication.isLoading || updateCommunication.isLoading}
        />
      </Modal.Content>
    </Modal>
  );
};
