import logoHomeWait from '../../assets/images/logo_home_wait.png';
import loading from '../../assets/images/loading.gif';

export const HomeWait = () => {
    return (
        <>
            <div style={{ height: "500px", display: "flex", justifyContent: "center", alignItems: " center" }}>
                <img src={logoHomeWait} style={{ width: "50%" }}></img>
                <br />
            </div>
            <div style={{ height: "10px", display: "flex", justifyContent: "center", alignItems: " center" }}>
                <img src={loading} style={{ width: "10%" }}></img>
            </div>
        </>
    );
}
