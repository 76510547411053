import { CommunicationFormDataProps } from "../../types/communication";

export const INITIAL_VALUES: CommunicationFormDataProps = {
  id: undefined,
  area: undefined,
  countryId: undefined,
  employeeTypeId: undefined,
  facilityId: undefined,
  email: undefined,
  subject: undefined,
  typeArquive: undefined,
  emailUser: undefined,
  user: undefined,
  date: undefined,
  fileName: undefined,
};
