import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { Segment, Container as ContainerSUI, Image } from "semantic-ui-react";
import { Banner } from "./Banner";
import { HeaderGoodyear } from "./Header";
import { Container, Content, Footer } from "./styles";
import MyContext from "../../contexts/auth";
import { HomeWait } from "../HomeWait";
import projectPackage from "../../../package.json";

export function Layout() {
  const { loading } = useContext(MyContext);
  const currentYear = new Date().getFullYear();

  return (
    <>
      {loading ? (
        <HomeWait />
      ) : (
        <Container>
          <div id='container__headerAndBanner'>
            <HeaderGoodyear />
            <Banner />
          </div>

          <Content>
            <Outlet />
          </Content>

          <Footer>
            <Segment id="footer" inverted vertical>
              <ContainerSUI
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Image src="/bar.png" />
                <div style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: 'center',
                  flexDirection: 'column'
                }}>
                  <div className="info__version-and-year">
                    <span className="info__version">v{projectPackage.version}</span>
                    <span className="info__year">© {currentYear}</span>
                  </div>
                  <span>
                    The Goodyear Tire & Rubber Company.
                  </span>
                </div>
              </ContainerSUI>
            </Segment>
          </Footer>
        </Container>
      )}
    </>
  );
}
