import { AxiosResponse } from "axios";
import api from "../api";

export type BenefitsFormData = {
  id: number;
  countryId: number;
  employeeTypeId: number;
  name: string;
  createdModified: string;
  size?: number;
  modifiedBy: string;
  fileName: string;
};

type Response = Request;

export async function updateBenefitRequest(
  token: string,
  data: BenefitsFormData
): Promise<Response> {
  try {
    const response: AxiosResponse<Response> = await api.put(
      "/benefits/",
      data,
      {
        params: { token },
        headers: {
          jwt: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }

    throw new Error();
  }
}
