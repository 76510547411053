import { useContext, useEffect, useMemo } from "react";
import { CardHome } from "../../components/CardHome";
import { URLS } from "../../types/env";
import MyContext from "../../contexts/auth";
import { Card } from '../../components/Card';
import { Container } from './styles';
import { useMutation } from '@tanstack/react-query';
import { auth } from '../../endpoints/auth';
import dataProcessing from '../../utils/dataProcessing';

const Home = () => {
  const { languageSelected, user, token } = useContext(MyContext);

  useEffect(() => {
    document.title = "GY TR Portal - Home";
  }, []);

  const downloadBenefitsPdf = useMutation(async () => {
    const data = await auth.getPdfUser(token.JWT);

    return dataProcessing.toBinary(data, false, "application/pdf");
  }, {
    onSuccess: () => {
      console.log('SUCCESS');
    },
    onError: () => {
      console.log('ERROR');
    }
  });

  const downloadBookletPdf = useMutation(async () => {
    const data = await auth.getBookletRequest({ token: token.JWT, language: languageSelected.locales });

    return dataProcessing.toBinary(data.byteArray, false, "application/pdf")
  }, {
    onSuccess: () => {
      console.log('SUCCESS');
    },
    onError: () => {
      console.log('ERROR');
    }
  });

  const countCards = useMemo(() => {
    let count = 0;
    const cardPermissions = {
      hasMyTotalRewardsStatement: user?.salary && user?.associated,
      hasMyBenefitGuide: user?.benefits,
      hasRecognitionProgram: user?.associated,
      hasTotalRewardsBooklet: user?.booklet,
      hasTotalRewardsSharePoint: user?.sharepointAccess,
    }

    if (cardPermissions.hasTotalRewardsSharePoint) {
      count += 1;
    }

    if (cardPermissions.hasMyBenefitGuide) {
      count += 1;
    }

    if (cardPermissions.hasRecognitionProgram) {
      count += 1;
    }

    if (cardPermissions.hasTotalRewardsBooklet) {
      count += 1;
    }

    if (cardPermissions.hasTotalRewardsSharePoint) {
      count += 1;
    }

    return count;
  }, [user]);

  return (
    <Container>
      <Card.CardGroup countCards={countCards}>
        <CardHome
          $type="LINK"
          $title={languageSelected?.myTotalRewardsStatement}
          $imgSource="/box1.png"
          $authorized={user?.salary && user?.associated}
          $link={URLS.URL_TOTAL_REWARDS_STATEMENT}
        >
          {languageSelected?.myTotalRewardsStatementDescription}
        </CardHome>

        <CardHome
          $type="PDF"
          $title={languageSelected?.myBenefitGuide}
          $imgSource="/box2.png"
          $authorized={user?.benefits}
          onClick={() => downloadBenefitsPdf.mutateAsync()}
          $loading={downloadBenefitsPdf.isLoading}
          >
          {languageSelected?.myBenefitGuideDescription}
        </CardHome>

        <CardHome
          $type="LINK"
          $title={languageSelected?.recognitionProgram}
          $imgSource="/box3.png"
          $authorized={user?.associated}
          $link={user?.linkRecognition}
          >
          {languageSelected?.recognitionProgramDescription}
        </CardHome>

        <CardHome
          $type="PDF"
          $title={languageSelected?.totalRewardsBooklet}
          $imgSource="/box5.png"
          $authorized={user?.booklet}
          onClick={() => downloadBookletPdf.mutateAsync()}
          $loading={downloadBookletPdf.isLoading}
          >
          {languageSelected?.totalRewardsBookletDescription}
        </CardHome>

        <CardHome
          $type="LINK"
          $title={languageSelected?.totalRewardsSharePoint}
          $imgSource="/box4.png"
          $link={URLS?.URL_TOTAL_REWARDS_SHAREPOINT}
          $authorized={user?.sharepointAccess}
        >
          {languageSelected?.totalRewardsSharePointDescription}
        </CardHome>
      </Card.CardGroup>
    </Container>
  );
};
export default Home;
