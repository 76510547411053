import { ModalCommunication } from "../../components/Modal/ModalCommunication";
import {
  Button,
  Container,
  Input,
  PaginationProps,
} from "semantic-ui-react";
import React, { useContext, useEffect, useState } from "react";
import MyContext from "../../contexts/auth";
import { getListCommunicationRequest } from "../../services/communication/getListComunicationResquest";
import {
  CommunicationFormDataProps,
} from "../../types/communication";
import { FormProvider, useForm } from "react-hook-form";
import { INITIAL_VALUES } from "./constants";
import { useQuery } from "@tanstack/react-query";
import { Table } from './components/Table';

const Communication = () => {
  const { token, languageSelected } = useContext(MyContext);
  const methods = useForm<CommunicationFormDataProps>();
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [subject, setSubject] = useState<string>();
  const [query, setQuery] = useState<string>();
  const [selectedCommunicationId, setSelectedCommunicationId] =
    useState<number>();
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);

  const [currentFile, setCurrentFile] = useState<File>();
  const [isDeleteFile, setIsDeleteFile] = useState(false);

  useEffect(() => {
    document.title = "GY TR Portal - Communication";
  }, []);

  const { data: communicationList } = useQuery(
    ["COMMUNICATION_LIST", query, page],
    async () =>
      getListCommunicationRequest({
        token: token.JWT,
        page,
        subject,
      }).finally(() => {
        setIsSearchLoading(false);
      })
  );

  const handlePageChange = (
    _: React.MouseEvent<HTMLAnchorElement>,
    { activePage }: PaginationProps
  ) => setPage(activePage as number);

  const handleFormModalOpen = (communicationFormId?: number) => {
    setIsFormModalOpen(true);
    setSelectedCommunicationId(communicationFormId);
  };

  const handleFormModalClose = () => {
    setIsFormModalOpen(false);
    setSelectedCommunicationId(undefined);

    setCurrentFile(undefined);
    setIsDeleteFile(false);

    methods.reset(INITIAL_VALUES);
  };

  function handleSearchClick() {
    setIsSearchLoading(true);
    setQuery(subject);
  }

  function handleEdit(id: number) {
    handleFormModalOpen(id);
    setIsUpdate(true);
  }

  return (
    <>
      <Container style={{ marginTop: "1em", minHeight: "297px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Input
            style={{ borderRadius: "5px 0 0 5px" }}
            onChange={(e, data) => setSubject(data.value)}
            value={subject}
            placeholder={`${languageSelected?.modal?.semantic?.table?.search} ${languageSelected?.modal?.semantic?.table?.subject}`}
          />
          <Button
            icon="search"
            style={{
              width: "5%",
              padding: "12px",
              marginLeft: "-1px",
              borderRadius: "0 5px 5px 0",
              zIndex: 1,
            }}
            onClick={() => handleSearchClick()}
            loading={!!isSearchLoading}
          />
        </div>
        <div>
          <Table
            communicationList={communicationList}
            page={page}
            handlePageChange={handlePageChange}
            handleEdit={handleEdit}
            handleFormModalOpen={handleFormModalOpen}
          />
        </div>
      </Container>

      <FormProvider {...methods}>
        <ModalCommunication
          open={isFormModalOpen}
          onOpen={() => handleFormModalOpen()}
          onClose={handleFormModalClose}
          handleFormModalClose={handleFormModalClose}
          communicationId={selectedCommunicationId}
          isUpdate={isUpdate}
          currentFile={currentFile}
          setCurrentFile={setCurrentFile}
          isDeleteFile={isDeleteFile}
          setIsDeleteFile={setIsDeleteFile}
        />
      </FormProvider>
    </>
  );
};
export default Communication;
