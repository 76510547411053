import { BooketDataProps } from '../../types/booklet';
import api from "../api";

export async function findBookletByIdRequest(id: number): Promise<BooketDataProps> {
  try {
    const response = await api.get(`/booklet/${id}`);

    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }

    throw new Error();
  }
}
