import { Table, Icon, Form, FormInputProps, Message } from "semantic-ui-react";
import { useFormContext } from "react-hook-form";

import "./styles.css";
import { useContext } from "react";
import AuthContext from "../../../contexts/auth";

interface IProps extends FormInputProps {
  label: string;
  watchFileName?: string;
  onDeleteFile(): void;
  file?: File;
}

export function InputFile({
  label,
  watchFileName,
  onDeleteFile,
  placeholder,
  file,
  ...rest
}: IProps) {
  const { languageSelected } = useContext(AuthContext);
  const { formState: { errors }} = useFormContext();

  return (
    <>
      <Form.Group grouped>
        {watchFileName ? (
          <>
            <b>{label}</b>
            <Table unstackable style={{ marginTop: "4px" }}>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <div className="text-overflow">{watchFileName}</div>
                  </Table.Cell>
                  <Table.Cell
                    content={
                      <Icon
                        name="trash"
                        color="red"
                        link
                        onClick={() => onDeleteFile()}
                      />
                    }
                    width={1}
                  />
                </Table.Row>
              </Table.Body>
            </Table>
            <Message positive>
              <Message.Header>
                {languageSelected?.modal?.semantic?.table?.fileName}
              </Message.Header>
              <p>{file ? file.name : watchFileName}</p>
            </Message>
          </>
        ) : (
          <Form.Input
            label={label}
            placeholder={placeholder}
            type="file"
            accept={[".ppt, .pptx, .xls, .xlsx, .pdf"]}
            icon="paperclip"
            fluid
            error={
              errors.employeeTypeId?.type === "required" &&
              languageSelected?.required
            }
            {...rest}
          />
        )}
      </Form.Group>
    </>
  );
}
