import Swal, { SweetAlertResult } from "sweetalert2";

export const showSwal = (message: string, response: any, buttonColor: string): any => {
    return Swal.fire({
        title: message,
        confirmButtonColor: buttonColor,
        icon: response
    }
    );
}

export const swallDelete = (languageSelected:any)  : Promise<SweetAlertResult<void>> => {
   return Swal.fire({
        title: languageSelected?.modal.swall.confirmation,
        text: languageSelected?.modal.swall.warning,
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: function () {
            return new Promise<void>(function (resolve) {
                setTimeout(function () {
                    resolve();
                }, 1000);
            });
        },
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: languageSelected?.yes,
        cancelButtonText: languageSelected?.no,
    });
}
