import {
	Control,
	Controller,
	FieldValues,
	Path,
	useFormState,
} from 'react-hook-form';
import { Form, FormInputProps } from 'semantic-ui-react';

type Props<T extends FieldValues> = FormInputProps & {
	control: Control<T>;
	name: Path<T>;
	required?: boolean;
	messageError?: string;

	label: string;
	placeholder?: string;
};

export function FormInput<T extends FieldValues>({
	control,
	name,
	required,
	messageError,

	label,
	placeholder,
	...rest
}: Props<T>) {
	const { errors } = useFormState<T>({
		control,
	});

	return (
		<Controller
			control={control}
			name={name}
			rules={{ required }}
			render={({ field: { value, onChange } }) => (
				<Form.Input
					label={label}
					placeholder={placeholder ?? label}
					value={value}
					onChange={onChange}
					required={required}
					error={
						required &&
						errors[name] &&
						(messageError ?? 'Error')
					}
					{...rest}
				/>
			)}
		/>
	);
}
