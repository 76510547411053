import { Button, Form, Modal, Placeholder } from "semantic-ui-react";
import { useCallback, useContext, useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import AuthContext from "../../../contexts/auth";
import { showSwal } from "../../../utils/swal";
import { queryClient } from "../../../services/queryClient";
import { useMutation, useQuery } from '@tanstack/react-query';
import { updateBookletRequest } from '../../../services/booklets/updateBookletRequest';
import { AxiosError } from 'axios';
import { findBookletByIdRequest } from '../../../services/booklets/findBookletRequest';
import { BookletFormDataProps, BookletFormDataRequestProps } from '../../../types/booklet';
import { saveBookletRequest } from '../../../services/booklets/saveBookletRequest';
import languageOptions from '../../../pages/Booklet/constants/countryOptions.json';
import { FormDropdown } from '../../Form/FormDropdown';

interface ModalPdfProps {
  bookletId?: number;
  onModalClose(): void;
}

const TYPE_PDF = "application/pdf";

export const ModalBooklet = ({ bookletId, onModalClose }: ModalPdfProps) => {
  const { languageSelected } = useContext(AuthContext);
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<BookletFormDataProps>();

  const languageWatch = useWatch({ control, name: 'language' });

  const {data: bookletData, isLoading: isBookletLoading } = useQuery([`BOOKLET_${bookletId}`],
    async () => findBookletByIdRequest(bookletId as number), {
      enabled: !!bookletId,
      retry: false,
      refetchOnMount: false
    });

  useEffect(() => {
    if (bookletData) {
      reset({
        language: bookletData?.fileName.split('_').at(-1)?.split('.')[0]
      });
    }
  }, [bookletData])

  const fileWatch = useWatch({ control, name: 'file' });

  const createBooklet = useMutation({
    mutationFn: async ({ file }: BookletFormDataRequestProps) => {
      return saveBookletRequest({ file })
    },
    onSuccess: () => {
      showSwal(languageSelected?.modal?.swall?.edit, "success", "green");

      queryClient.invalidateQueries(["BOOKLETS_LIST"]);
      onModalClose();
      reset({
        language: undefined,
        file: undefined
      });
    },
    onError: (error: AxiosError<any>) => {
      showSwal(error.message, "error", "red");
    }
  });

  const updateBooklet = useMutation({
    mutationFn: async ({ file }: BookletFormDataRequestProps) => {
      return updateBookletRequest({ file })
    },
    onSuccess: () => {
      showSwal(languageSelected?.modal?.swall?.edit, "success", "green");

      queryClient.invalidateQueries(["BOOKLETS_LIST"]);
      onModalClose();
      reset({
        language: undefined,
        file: undefined
      });
    },
    onError: (error: AxiosError<any>) => {
      showSwal(error.message, "error", "red");
    }
  });

  const toFormData = (file: File) => {
    const formData = new FormData();

    if (bookletId) {
      formData.append('id', String(bookletId))
    }
    formData.append('language', languageWatch);
    formData.append('file', file);

    return formData;
  };

  const handleModalClose = () => {
    onModalClose();
    reset({
      language: undefined,
      file: undefined
    });
  }

  const onSubmit = ({ file }: BookletFormDataProps) => {
    const formData = toFormData(file);

    if (file.type !== TYPE_PDF) {
      return undefined
    }

    if (bookletId) {
      return updateBooklet.mutateAsync({
        file: formData,
      })
    }

    return createBooklet.mutateAsync({
      file: formData,
    })
  }

  const getModalTitle = useCallback(() => {
    if (bookletId) {
      if (isBookletLoading) {
        return (
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line />
            </Placeholder.Header>
          </Placeholder>
        );
      }

      return bookletData?.fileName;
    }

    return languageSelected?.modal?.semantic?.table?.ModalBookletTitle;
  }, [bookletId, isBookletLoading, bookletData])

  return (
    <Modal
      onClose={handleModalClose}
      open
      closeIcon
      size="small"
    >
      <Modal.Header>{getModalTitle()}</Modal.Header>

      <Modal.Content image scrolling>
        <Modal.Description>
          <Form
            onSubmit={handleSubmit(onSubmit)}
          >
            <FormDropdown
              control={control}
              name="language"
              required={true}
              label={languageSelected?.modal?.semantic?.table?.fileLanguage}
              placeholder={`${languageSelected?.select} ${languageSelected?.modal?.semantic?.table?.companyCode}`}
              options={languageOptions}
              error={errors.language && languageSelected?.modal?.semantic?.table?.error.requiredField}
              loading={isBookletLoading && !!bookletId}
            />

            <Controller
              control={control}
              name='file'
              rules={{
                required: true
              }}
              render={({ field: { onChange } }) => (
                <Form.Input
                  type="file"
                  accept={[TYPE_PDF]}
                  label={languageSelected?.modal?.semantic?.table?.file}
                  onChange={(e) => onChange(e.currentTarget.files?.[0])}
                  error={
                    (errors.file && languageSelected?.modal?.semantic?.table?.error.requiredField) ||
                    (fileWatch && fileWatch?.type !== TYPE_PDF && languageSelected?.modal?.semantic?.table?.error.fileType)
                  }
                  fluid
                />
              )}
            />

            <p>
              {
                languageSelected?.modal?.semantic?.table
                  ?.fileTypesMessageBenefits
              }
            </p>

            <Button
              type="submit"
              loading={createBooklet.isLoading || updateBooklet.isLoading}
              disabled={
                (!!errors.file?.type) ||
                (fileWatch && fileWatch?.type !== TYPE_PDF)
              }
              fluid
            >
              {languageSelected?.send}
            </Button>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
