import { FileUpload } from "../models/FileUpload";

function toBinary(base64: string, download: boolean, contentType: string, name?: string): any {
    const binaryToString = window.atob(base64);
    const len = binaryToString.length;
    const arrayBytes = new Uint8Array(len);

    arrayBytes.forEach((_item, indice) => {
        arrayBytes[indice] = binaryToString.charCodeAt(indice);
    });

    return openPdf(arrayBytes.buffer, download, contentType, name);
}

const mountFormData = (data: FileUpload): FormData => {
    const sendRequest = new FormData();
    sendRequest.append("path", data.path);
    sendRequest.append("fileName", data.fileName);
    sendRequest.append("tag", data.tag.toString());
    sendRequest.append("tagValues", data.tagValues.toString());
    sendRequest.append("multipartFile", data.multipartFile)
    sendRequest.append("metadata", data.metadata.toString());
    sendRequest.append("metadatasValues", data.metadatasValues.toString());

    return sendRequest;
}


function openPdf(pdf: any, download: boolean, contentType: string, name?: string) {
    const blob = new Blob([pdf], { type: contentType });
    const url = URL.createObjectURL(blob);
    if (download) {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = name || "";
        link.click();
        return;
    }
    window.open(url + "#view=fitV", "_blank");
}

const randon = (): number => {
    const array = new Uint32Array(1);
    return window.crypto.getRandomValues(array)[0];
}

export default {
    toBinary,
    mountFormData,
    openPdf,
    randon
};
