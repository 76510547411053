import {
	Control,
	Controller,
	FieldValues,
	Path,
	useFormState,
} from 'react-hook-form';
import { DropdownItemProps, Form, FormDropdownProps } from 'semantic-ui-react';

type Props<T extends FieldValues> = FormDropdownProps & {
	control: Control<T>;
	name: Path<T>;
	required?: boolean;
	messageError?: string;

	label: string;
	placeholder?: string;
	options?: DropdownItemProps[];
};

export function FormDropdown<T extends FieldValues>({
	control,
	name,
	required,
	messageError,

	label,
	placeholder,
	options,
	...rest
}: Props<T>) {
	const { errors } = useFormState<T>({
		control,
	});

	return (
		<Controller
			control={control}
			name={name}
			rules={{ required: required }}
			render={({ field: { value, onChange } }) => (
				<Form.Dropdown
					label={label}
					placeholder={placeholder}
					selection
					clearable
					options={options ?? []}
					value={value}
					onChange={(_, data) => onChange(data.value)}
					required={required}
					error={
						required &&
						errors[name] &&
						messageError
					}
					{...rest}
				/>
			)}
		/>
	);
}
