import { CardContentStyled, CardDescriptionStyled, CardFooterStyled, CardGroupStyled, CardHeaderStyled, CardImage, CardStyled, CardUnauthorized, Container } from './styles'
import { Loader } from 'semantic-ui-react';

type CardComponentProps = React.FC<CardProps> & {
    CardGroup: typeof CardGroup;
    Header: typeof CardHeader;
    Content: typeof CardContent;
    Description: typeof CardDescription;
    Footer: typeof CardFooter;
}

export type CardImageProps = {
    src: string;
}

type CardProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
    children: React.ReactNode;
    isLoading?: boolean;
    image?: CardImageProps;
    isAuthorized?: boolean;
    unauthorizedMessage?: string;
}

type CardGroupProps = {
    children: React.ReactNode;
    countCards: number;
}

type CardHeaderProps = {
    children: React.ReactNode;
}

type CardContentProps = {
    children: React.ReactNode;
}

type CardDescriptionProps = {
    children: React.ReactNode;
}

type CardFooterProps = {
    children: React.ReactNode;
}

export const Card: CardComponentProps = ({ children, image, isAuthorized, unauthorizedMessage, isLoading, ...rest }) => {

  return (
    <Container isAuthorized={isAuthorized} {...rest}>
        <CardStyled isAuthorized={isAuthorized}>
            {image && <CardImage src={image.src}/>}
            {children}
        </CardStyled>

        {!isAuthorized && !isLoading &&
            <CardUnauthorized className='hidden'children={unauthorizedMessage}/>
        }

        {isLoading &&
            <Loader active />
        }
    </Container>

  )
}

const CardHeader: React.FC<CardHeaderProps> = ({ children }) => {
    return (
        <CardHeaderStyled>
            {children}
        </CardHeaderStyled>
    )
}

const CardGroup: React.FC<CardGroupProps> = ({ children, countCards }) => {
    return (
        <CardGroupStyled countCards={countCards}>
            {children}
        </CardGroupStyled>
    )
}

const CardContent: React.FC<CardContentProps> = ({ children }) => {
    return (
        <CardContentStyled>
            {children}
        </CardContentStyled>
    )
}

const CardDescription: React.FC<CardDescriptionProps> = ({ children }) => {
    return (
        <CardDescriptionStyled>
            {children}
        </CardDescriptionStyled>
    )
}

const CardFooter: React.FC<CardFooterProps> = ({ children }) => {
    return (
        <CardFooterStyled>
            {children}
        </CardFooterStyled>
    )
}

Card.Header = CardHeader;
Card.Content = CardContent;
Card.Description = CardDescription;
Card.Footer = CardFooter;
Card.CardGroup = CardGroup;
