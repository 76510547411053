import { AxiosResponse } from "axios";
import { CommunicationFormDataProps } from "../../types/communication";
import api from "../api";

export async function editCommunicationRequest(
  token: string,
  data: CommunicationFormDataProps
): Promise<Response> {
  try {
    const response: AxiosResponse<Response> = await api.put(
      "/communication/",
      data,
      {
        params: { token },
        headers: {
          jwt: token,
        },
      }
    );

    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }

    throw new Error();
  }
}
