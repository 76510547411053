import Home from "./pages/Home/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useContext, useEffect } from "react";
import MyContext, { Auth } from "./contexts/auth";
import AdminPdf from "./pages/AdminPdf";
import { Data } from "./contexts/data";
import Communication from "./pages/Communication";

import ReactGA from "react-ga4";
import { URLS } from "./types/env";
import { Layout } from "./components/Layout";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./services/queryClient";
import Booklets from './pages/Booklet';

ReactGA.initialize(URLS.TAG_GOOGLE);

const App = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  function RequireAuth({ children }: { children: JSX.Element }) {
    const { user } = useContext(MyContext);

    if (!user?.manager) {
      return <Navigate to="/web" />;
    }
    return children;
  }

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Auth>
            <Data>
              <Routes>
                <Route element={<Layout />}>
                  <Route path="/" element={<Navigate replace to="/web" />} />
                  <Route path="/web" element={<Home />} />
                  <Route path="/communication" element={<Communication />} />
                  <Route
                    path="/adm"
                    element={
                      <RequireAuth>
                        <AdminPdf />
                      </RequireAuth>
                    }
                  />
                  <Route
                    path="/booklets"
                    element={
                      <RequireAuth>
                        <Booklets />
                      </RequireAuth>
                    }
                  />
                </Route>
              </Routes>
            </Data>
          </Auth>
        </Router>
      </QueryClientProvider>
    </>
  );
};

export default App;
