
import { useContext } from 'react';
import { Card } from '../Card';
import MyContext from "../../contexts/auth";

type CardHomeType = "PDF" | "LINK";

type CardHomeProps = {
  children?: React.ReactNode;
  onClick?: () => void;
  $imgSource: string;
  $title?: string;
  $authorized?: boolean;
  $link?: string;
  $type?: CardHomeType;
  $loading?: boolean;
};

export const CardHome: React.FC<CardHomeProps> = ({ children, $imgSource, $title, $authorized, $link, $type = 'LINK', $loading, onClick }) => {
  const { languageSelected } = useContext(MyContext);

  const getLink = (linkHref?: string) => {
    const linkHtml = document.createElement('a');
    linkHref && linkHtml.setAttribute("href", linkHref);
    window.open(linkHtml.href, "_blank");
  }

  const onClickOptions = [
    {
      typeValidation: 'PDF',
      authorizedValidation: true,
      onClick: () => onClick && onClick(),
    },
    {
      typeValidation: 'LINK',
      authorizedValidation: true,
      onClick: () => getLink($link),
    },
  ]

  const handleClick = () => {
    const index = onClickOptions.findIndex(({typeValidation, authorizedValidation}) => typeValidation === $type && authorizedValidation === $authorized);

    if (index !== -1) {
      return onClickOptions[index].onClick();
    }

    return undefined
  };

  return (
    <Card image={{src: $imgSource}} isAuthorized={$authorized} unauthorizedMessage={languageSelected?.unauthorizedMessage} isLoading={$loading} onClick={handleClick}>
      <Card.Content>
        <Card.Header>{$title}</Card.Header>
        <Card.Description>
          {children}
        </Card.Description>
      </Card.Content>
    </Card>
  );
}
