import api from "../api";
import { BookletListProps } from '../../types/booklet';

export async function bookletsRequest(): Promise<BookletListProps> {
  try {
    const response = await api.get("/booklet/");

    return response.data;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(error.message);
    }

    throw new Error();
  }
}
